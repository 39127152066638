import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";

import accidentDashboard from "./accidentDashboard";
import accidents from "./accidents";
import answeredAuthorizations from "./answeredAuthorizations";
import appointments from "./appointments";
import art from "./art";
import arts from "./arts";
import auth from "./auth";
import authorizations from "./authorizations";
import connectivityResponse from "./connectivityResponse";
import dashboard from "./dashboard";
import documentType from "./documentType";
import emails from "./emails";
import fields from "./fields";
import files from "./files";
import forms from "./forms";
import importForms from "./importForms";
import password from "./password";
import pendingAuthorizations from "./pendingAuthorizations";
import providers from "./providers";
import reports from "./reports";
import userPreferences from "./userPreferences";
import users from "./users";

const allReducers = combineReducers({
  authorizations,
  accidents,
  appointments,
  art,
  auth,
  answeredAuthorizations,
  dashboard,
  userPreferences,
  pendingAuthorizations,
  providers,
  users,
  password,
  emails,
  arts,
  accidentDashboard,
  forms,
  reports,
  documentType,
  fields,
  importForms,
  files,
  connectivityResponse,
});

const rootReducer = (state, action) => {
  if (action.type === "SIGNOUT_REQUEST") {
    Object.keys(state).forEach((key) => storage.removeItem(`persist:${key}`));
    const newState = undefined;

    return allReducers(newState, action);
  }

  return allReducers(state, action);
};

export default rootReducer;
