import { makeStyles } from "@material-ui/core";
import { Description, FindInPage } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getClinicHistoryHTML,
  getDocuments,
} from "../../../../../actions/accidentDashboard";
import {
  postSignForm,
  setChangeDocument,
  setOpenAccidentDocumentsDetail,
} from "../../../../../actions/forms";
import snackbarUtils from "../../../../../utils/snackbarUtils";
import { parseHTMLToReact } from "../../../../../utils/util";
import PrintAndDownloadDialog from "../../../../commons/dialog/PrintAndDownloadDialog";
import ConfirmDialog from "../../../../commons/dialog/confirmDialog";
import CardTitle from "../cardTitle";
import AccidentDocumentsCards from "./accidentDocumentsCards";
import AccidentDocumentsDetail from "./detail/accidentDocumentsDetail";
import ErroresConectividadDialog from "./errorDialog/ErroresConectividadDialog";
import SignForm from "./sign/signForm";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
}));

const AccidentDocuments = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const documents = useSelector((state) => state.accidentDashboard.documents);
  const accident = useSelector((state) => state.accidentDashboard.accident);
  const clinicHistoryHTML = useSelector(
    (state) => state.accidentDashboard.clinicHistoryHTML,
  );
  const openAccidentDocumentsDetail = useSelector(
    (state) => state.forms.openAccidentDocumentsDetail,
  );

  const [selectedDocument, setSelectedDocument] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");

  const [openSignForm, setOpenSignForm] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [fromErrorDialog, setFromErrorDialog] = useState(false);

  const [openErroresConectividadDialog, setOpenErroresConectividadDialog] =
    useState(false);

  useEffect(() => {
    dispatch(getDocuments(accident.idSiniestro, accident.mlCloudCoreArtId));
  }, []);

  const handleOpenDetail = (document, error) => {
    setSelectedDocument(!error ? document : { ...document, ...error });
    dispatch(setOpenAccidentDocumentsDetail(true));
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const generateClinicHistoryHTML = () => {
    setModalTitle("Historia Clínica");

    setOpenModal(true);
    dispatch(
      getClinicHistoryHTML({
        accidentId: accident.idSiniestro,
        WRIId: accident.mlCloudCoreArtId,
      }),
    );
  };

  const generateClinicHistoryHTMLFpa = () => {
    setModalTitle("Pedidos de Autorización");
    setOpenModal(true);
    dispatch(
      getClinicHistoryHTML({
        accidentId: accident.idSiniestro,
        WRIId: accident.mlCloudCoreArtId,
        formType: "Fpa",
      }),
    );
  };

  const handleOpenDetailFromError = (document, error) => {
    setFromErrorDialog(true);
    handleOpenDetail(document, error);
  };

  const handleOpenSignForm = (document) => {
    setSelectedDocument(document);
    setOpenSignForm(true);
  };

  const handleOpenConfirmDialog = () => {
    setConfirmDialogOpen(true);
  };
  const handleCloseErroresConectividad = () => {
    setOpenErroresConectividadDialog(false);
  };
  const handleCloseSignForm = () => {
    setSelectedDocument({});
    setOpenSignForm(false);
    handleCloseConfirmDialog();
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  const handleOpenErroresConectividad = (document) => {
    setSelectedDocument(document);
    setOpenErroresConectividadDialog(true);
  };
  const handleSubmitSignForm = (values) => {
    const form = {
      ...values,
      marcaFirmado: values.marcaFirmado === "Si" ? true : false,
      nroFormulario: selectedDocument.nroFormulario,
      siniestro: accident.idSiniestro,
    };

    dispatch(postSignForm(form)).then(() => {
      snackbarUtils.success("Firma enviada correctamente");
      handleCloseSignForm();
      dispatch(getDocuments(accident.idSiniestro, accident.mlCloudCoreArtId));
    });
  };

  const icons = [
    {
      Icon: FindInPage,
      tooltip: "Imprimir FPA",
      onClick: generateClinicHistoryHTMLFpa,
      disabled: false,
    },
    {
      Icon: Description,
      tooltip: "Emitir H.C.",
      onClick: generateClinicHistoryHTML,
      disabled: false,
    },
  ];

  return (
    <>
      <div className={classes.container}>
        <CardTitle
          title="DOCUMENTOS DEL SINIESTRO"
          color="primary"
          icons={icons}
        />
        <AccidentDocumentsCards
          data={documents.data}
          loading={documents.isFetching}
          handleOpenDetail={handleOpenDetail}
          handleOpenSign={handleOpenSignForm}
          setOpenErroresConectividadDialog={handleOpenErroresConectividad}
        />
        <AccidentDocumentsDetail
          open={openAccidentDocumentsDetail}
          data={documents.data}
          selectedDocument={selectedDocument}
          setSelectedDocument={setSelectedDocument}
          fromErrorDialog={fromErrorDialog}
          handleCloseErroresConectividad={handleCloseErroresConectividad}
          setFromErrorDialog={setFromErrorDialog}
        />
        <PrintAndDownloadDialog
          maxWidth="lg"
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          childComponent={
            <React.Fragment>
              {parseHTMLToReact(clinicHistoryHTML.data)}
            </React.Fragment>
          }
          loading={clinicHistoryHTML.isFetching || !openModal}
          printStyle={{ margin: "20px" }}
          title={modalTitle}
          showDownloadButton={false}
          reactToPrintProps={{ suppressErrors: true }}
        />
        <SignForm
          open={openSignForm}
          handleConfirmClose={handleOpenConfirmDialog}
          handleClose={handleCloseSignForm}
          handleSubmit={handleSubmitSignForm}
          document={selectedDocument}
        />
      </div>
      <div>
        <ConfirmDialog
          open={confirmDialogOpen}
          handleClose={handleCloseConfirmDialog}
          handleConfirm={handleCloseSignForm}
          title="CERRAR VENTANA"
          text="¿Está seguro que desea cancelar esta operación? En caso de aceptar, se perderá la información ingresada"
        />
      </div>
      <ErroresConectividadDialog
        setChangeDocument={setChangeDocument}
        open={openErroresConectividadDialog}
        handleClose={handleCloseErroresConectividad}
        selectedDocument={selectedDocument}
        handleOpenDetail={handleOpenDetailFromError}
        handleCloseErroresConectividad={handleCloseErroresConectividad}
      />
    </>
  );
};

export default AccidentDocuments;
