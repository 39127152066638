import { Dialog, DialogContent, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAppointmentsCalendar,
  getAvailableDocumentsToAdd,
  setSelectedAccident,
} from "../../../../actions/accidentDashboard";
import { clearIdTurnos } from "../../../../actions/appointments";
import {
  dismissConnectivityErrorAction,
  getAllConnectivityErrors,
  setTime,
} from "../../../../actions/connectivityResponse";
import {
  clearFpt,
  clearIdTurnosForms,
  getFormModel,
  setCloseAddForm,
  setOpenAccidentDocumentsDetail,
  setOpenAddForm,
} from "../../../../actions/forms";
import snackbarUtils from "../../../../utils/snackbarUtils";
import ConfirmDialog from "../../../commons/dialog/confirmDialog";
import AddFormHeader from "../../accident/accidentDashboard/addDocumentation/addFormHeader";
import AccidentDocumentsDetail from "../../accident/accidentDashboard/documents/detail/accidentDocumentsDetail";
import FptContainer from "../../forms/fpt/fptContainer";
import ErroresConectividadTable from "./ConnectivityErrorsTable";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    height: "100vh",
    padding: 0,
  },
}));
const ConnectivityErrorsContainer = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [fptLocal, setFptLocal] = useState({});
  const [selectedDocument, setSelectedDocument] = useState(false);
  const [fromErroresConectividad, setFromErroresConectividad] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const time = useSelector(
    (state) => state.connectivityResponse.connectivityErrors.time,
  );
  const namesArts = useSelector((state) => state.art.shortNameArt);
  const erroresConectividad = useSelector(
    (state) => state.connectivityResponse.allConnectivityErrors,
  );
  const openAccidentDocumentsDetail = useSelector(
    (state) => state.forms.openAccidentDocumentsDetail,
  );
  const documents = useSelector((state) => state.accidentDashboard.documents);
  const accident = useSelector((state) => state.accidentDashboard.accident);
  const fpt = useSelector((state) => state.forms.fpt);
  const model = useSelector((state) => state.forms.model);
  useEffect(() => {
    dispatch(getAllConnectivityErrors(time));
  }, []);
  const handleOpenAddForm = (form) => {
    dispatch(setOpenAddForm(form));
  };
  const handleChange = (event) => {
    dispatch(setTime(event.target.value));
    dispatch(getAllConnectivityErrors(event.target.value));
  };
  const handleDesestimar = (rowData) => {
    dispatch(
      dismissConnectivityErrorAction(
        rowData.idRespError,
        rowData.mlCloudCoreArtId,
      ),
    ).then(() => {
      dispatch(getAllConnectivityErrors(time)).then(() => {
        snackbarUtils.success("Error desestimado correctamente");
      });
    });
  };
  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };
  const handleOpenConfirmDialog = () => {
    setConfirmDialogOpen(true);
  };
  const handleOpenEditForm = (rowData) => {
    dispatch(
      getAvailableDocumentsToAdd(rowData.siniestro, rowData.mlCloudCoreArtId),
    );
    dispatch(
      getAppointmentsCalendar(rowData.siniestro, rowData.mlCloudCoreArtId),
    );
    setFromErroresConectividad(true);
    setSelectedDocument({
      ...rowData,
    });
    dispatch(
      setSelectedAccident({
        ...rowData,
        idSiniestro: rowData.siniestro,
        apellidoTrabajador: accident.trabajador,
      }),
    );
    dispatch(setOpenAccidentDocumentsDetail(true));
  };

  const handleCloseAddForm = () => {
    handleCloseConfirmDialog();
    dispatch(setOpenAccidentDocumentsDetail(false));
    dispatch(clearIdTurnosForms());
    dispatch(clearIdTurnos());
    dispatch(setCloseAddForm());
    if (fpt.isOpen) {
      dispatch(clearFpt());
    }
  };

  useEffect(() => {
    if (fpt.isOpen) {
      handleOpenAddForm({
        formulario: "Fpt",
        nombre: "Formulario de Pedido de Traslado",
      });
      if (accident.mlCloudCoreArtId != null && accident.idSiniestro != null) {
        dispatch(
          getFormModel("Fpt", accident.mlCloudCoreArtId, accident.idSiniestro),
        );
        setFptLocal({
          formulario: "Fpt",
          nombre: "Formulario de Pedido de Traslado",
        });
      }
    }
  }, [fpt.isOpen]);

  return (
    <div>
      <ErroresConectividadTable
        data={erroresConectividad.data?.registers || []}
        loading={erroresConectividad.isFetching}
        namesArts={namesArts}
        fetchData={() => dispatch(getAllConnectivityErrors(time))}
        title="Errores Conectividad"
        handleChangeTime={handleChange}
        handleDesestimar={handleDesestimar}
        handleOpenEditForm={handleOpenEditForm}
      />
      <AccidentDocumentsDetail
        open={openAccidentDocumentsDetail}
        data={documents.data}
        selectedDocument={selectedDocument}
        setSelectedDocument={setSelectedDocument}
        fromErroresConectividad={fromErroresConectividad}
        setFromErroresConectividad={setFromErroresConectividad}
      />
      <Dialog
        open={fpt.isOpen && model ? true : false}
        onClose={handleCloseAddForm}
        fullWidth
        maxWidth="md"
        disableBackdropClick={true}>
        <AddFormHeader
          formName={fptLocal ? fptLocal.nombre : ""}
          handleClose={handleOpenConfirmDialog}
        />
        <DialogContent className={classes.container}>
          <FptContainer
            handleClose={handleCloseAddForm}
            handleOpenConfirmDialog={handleOpenConfirmDialog}
            isView={false}
            idArt={accident.mlCloudCoreArtId}
            siniestroProps={accident.idSiniestro}
          />
        </DialogContent>
      </Dialog>
      <div>
        <ConfirmDialog
          open={confirmDialogOpen}
          handleClose={handleCloseConfirmDialog}
          handleConfirm={handleCloseAddForm}
          title="CERRAR VENTANA"
          text="¿Está seguro que desea cancelar esta operación? En caso de aceptar, se perderá la información ingresada"
        />
      </div>
    </div>
  );
};

export default ConnectivityErrorsContainer;
