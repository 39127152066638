import React from "react";
import { HelpOutline } from "@material-ui/icons";
import { IconButton, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  icon: {
    marginLeft: 5,
    color: "#858a93",
  },
}));

const IconToJira = () => {
  const handleClickHelp = () => {
    window.open(
      "https://accessinformatica.atlassian.net/servicedesk/customer/portals",
    );
  };
  const classes = useStyles();

  return (
    <React.Fragment>
      <div style={{ display: "flex" }}>
        <Tooltip title="Ayuda" arrow>
          <IconButton
            size="small"
            onClick={handleClickHelp}
            className={classes.icon}>
            <HelpOutline />
          </IconButton>
        </Tooltip>
      </div>
    </React.Fragment>
  );
};

export default IconToJira;
