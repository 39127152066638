import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const ConfirmDeleteDialog = ({
  open,
  handleClose,
  handleConfirm,
  title,
  text,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} disableBackdropClick={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <p>{text}</p>
      </DialogContent>
      <DialogActions style={{ margin: 10 }}>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={handleConfirm}
          style={{ backgroundColor: "red", color: "white" }}
          variant="contained">
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteDialog;
