import React, { useState } from "react";
import {
  CircularProgress,
  InputAdornment,
  MenuItem,
  TextField,
} from "@material-ui/core";
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress } from "react-places-autocomplete";
import SnackbarUtils from "../../../utils/snackbarUtils";

const GooglePlacesAutocomplete = ({ handleSubmit, ...props }) => {
  const [address, setAddress] = useState("");
  const [loadingGeocode, setLoadingGeocode] = useState(false);

  const handleSelect = (value) => {
    setAddress(value);
    setLoadingGeocode(true);
    geocodeByAddress(value)
      .then((results) => {
        const obj = {};
        results[0].address_components.forEach((a) => {
          a.types.forEach((t) => {
            switch (t) {
              case "route":
                obj.calle = a.long_name;
                break;
              case "street_number":
                obj.numero = a.long_name;
                break;
              case "administrative_area_level_2":
                obj.localidad = a.long_name;
                break;
              case "administrative_area_level_1":
                obj.provincia = a.long_name.replace("Provincia de ", "");
                break;
              case "postal_code":
                obj.codigoPostal = a.long_name.replace(/\D/g, "");
                break;
              default:
                break;
            }
          });
        });
        handleSubmit(obj);
        setLoadingGeocode(false);
      })
      .catch((error) => {
        SnackbarUtils.error("No se encontraron resultados");
        setLoadingGeocode(false);
      });
  };

  const onError = (status, clearSuggestions) => {
    SnackbarUtils.error("No se encontraron resultados");
    clearSuggestions();
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={setAddress}
      onSelect={handleSelect}
      onError={onError}
      searchOptions={{
        componentRestrictions: {
          country: "ar",
        },
        types: ["address"],
      }}
      debounce={500}
      shouldFetchSuggestions={address.length > 3}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={{ flex: 1 }}>
          <TextField
            {...getInputProps()}
            {...props}
            InputProps={{
              endAdornment: (loading || loadingGeocode) && (
                <InputAdornment position="end">
                  <CircularProgress size={30} />
                </InputAdornment>
              ),
            }}
          />
          {suggestions.map((suggestion) => (
            <MenuItem
              {...getSuggestionItemProps(suggestion)}
              key={suggestion.placeId}
            >
              <em>{suggestion.description}</em>
            </MenuItem>
          ))}
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default GooglePlacesAutocomplete;
