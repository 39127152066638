import { applyMiddleware, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import promise from "redux-promise-middleware";
import thunk from "redux-thunk";

import reducer from "./reducers";

import { reduxErrorMiddleware } from "./middlewares/reduxErrorMiddleware";
import refreshTokenMiddleware from "./middlewares/refreshTokenMiddleware";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "userPreferences"],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const middleware = [
  thunk,
  refreshTokenMiddleware,
  reduxErrorMiddleware,
  promise,

];

export const store = createStore(
  persistedReducer,
  applyMiddleware(...middleware),
);

export const persistor = persistStore(store);
