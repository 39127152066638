import React from "react";
import {
  InfoOutlined,
  AssignmentOutlined,
  AssignmentLateOutlined,
  VideoLibraryOutlined,
} from "@material-ui/icons";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Divider,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AboutDialog from "../about/about";
import TermsConditionsDialog from "../termsConditions/termsConditions";
import PrivacyPoliciesDialog from "../termsConditions/privacyPolicies";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: 5,
    color: "#858a93",
  },
  menuItem: {
    marginRight: -20,
  },
}));

const IconMenuHelp = () => {
  const classes = useStyles();

  const [openAbout, setOpenAbout] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(null);
  const [openTerms, setOpenTerms] = React.useState(false);
  const [openPrivacy, setOpenPrivacy] = React.useState(false);

  const handleClickGuide = () => {
    setOpenMenu(null);
  };

  const handleClickAbout = () => {
    setOpenMenu(null);
    setOpenAbout(true);
  };

  const handleClickTermns = () => {
    setOpenMenu(null);
    setOpenTerms(true);
  };

  const handleClickPrivacy = () => {
    setOpenMenu(null);
    setOpenPrivacy(true);
  };

  const handleClose = (event) => {
    setOpenAbout(false);
    setOpenMenu(null);
    setOpenTerms(false);
    setOpenPrivacy(false);
  };

  const handleClickMenu = (event) => {
    setOpenMenu(event.currentTarget);
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex" }}>
        <Tooltip title="Opciones" arrow>
          <IconButton
            size="small"
            className={classes.icon}
            onClick={handleClickMenu}>
            <ViewHeadlineIcon />
          </IconButton>
        </Tooltip>
      </div>
      <Menu
        id="simple-menu"
        anchorEl={openMenu}
        keepMounted
        open={Boolean(openMenu)}
        onClose={handleClose}
        style={{ marginTop: 40, marginLeft: 0 }}>
        <MenuItem onClick={handleClickGuide} component={Link} to="/tutorials">
          <ListItemIcon size="small" className={classes.menuItem}>
            <VideoLibraryOutlined />
          </ListItemIcon>
          <ListItemText primary="Guías de Usuario" />
        </MenuItem>
        <Divider variant="middle" />
        <MenuItem onClick={handleClickTermns}>
          <ListItemIcon size="small" className={classes.menuItem}>
            <AssignmentOutlined />
          </ListItemIcon>
          <ListItemText primary="Términos y Condiciones" />
        </MenuItem>
        <MenuItem onClick={handleClickPrivacy}>
          <ListItemIcon size="small" className={classes.menuItem}>
            <AssignmentLateOutlined />
          </ListItemIcon>
          <ListItemText primary="Políticas de Privacidad y Confidencialidad" />
        </MenuItem>
        <Divider variant="middle" />
        <MenuItem onClick={handleClickAbout}>
          <ListItemIcon size="small" className={classes.menuItem}>
            <InfoOutlined />
          </ListItemIcon>
          <ListItemText primary="Acerca de..." />
        </MenuItem>
      </Menu>
      <AboutDialog open={openAbout} onClose={handleClose} />
      <TermsConditionsDialog open={openTerms} onClose={handleClose} />
      <PrivacyPoliciesDialog open={openPrivacy} onClose={handleClose} />
    </React.Fragment>
  );
};

export default IconMenuHelp;
