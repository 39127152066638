import { FormControlLabel, MenuItem, Radio } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import FormikActions from "../../../commons/formik/formikActions";
import FormikAutocomplete from "../../../commons/formik/formikAutocomplete";
import FormikDateTimePicker from "../../../commons/formik/formikDateTimePicker";
import FormikRadio from "../../../commons/formik/formikRadio";
import FormikSelect from "../../../commons/formik/formikSelect";
import FormikTextFieldSpeech from "../../../commons/formik/formikTextFieldSpeech";
import DropZone from "../dropZone/dropZone";
import FormSectionDivider from "../formSectionDivider";
import { useStyles } from "../formStyle";
import { fptValidation, validateRequiredFields } from "../formValidation";
import DireccionFpt from "./direccionFpt";




const Fpt = ({
  handleClose,
  files,
  setFiles,
  handleSubmit,
  model,
  fptModel,
  roles,
  selectedDocument,
  idArt,
  fromErrorDialog,
  fromErroresConectividad,
  disableDateUpdate,
}) => {
  const classes = useStyles();
  const [attachmentError, setAttachmentError] = useState(false);
  const [direccionesHabitualesDesde, setDireccionesHabitualesDesde] = useState([
    ...model.fields.direccionesHabituales.options,
  ]);
  const [direccionesHabitualesHasta, setDireccionesHabitualesHasta] = useState([
    ...model.fields.direccionesHabituales.options,
  ]);

  const fields = model.fields;
  const disabled =
    roles && !fromErrorDialog && !fromErroresConectividad
      ? !roles.editar
      : false;
  const fechaHoraSolicitudMinDate = fields.fechaHoraSolicitud.validations
    ? fields.fechaHoraSolicitud.validations.minDate
    : undefined;


  return (
    <Formik
      initialValues={fptModel}
      validationSchema={() => fptValidation(fechaHoraSolicitudMinDate)}
      validate={(values) => validateRequiredFields(values, fields)}
      onSubmit={(values) => {
        if (model.configuration.requiresAttachment && files.length === 0) {
          setAttachmentError(true);
        } else {
          handleSubmit(values, idArt);
        }
      }}>
      {({ values, setFieldValue }) => (
        <Form className={classes.container}>
          <div className={classes.fieldContainer}>
            <FormSectionDivider title="Traslado" />
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikDateTimePicker
                  name="fechaHoraSolicitud"
                  label={`Solicitado el ${fields.fechaHoraSolicitud.required ? "*" : ""
                    }`}
                  disableFuture
                  inputVariant="outlined"
                  className={classes.input}
                  disabled={disabled || disableDateUpdate}
                  minDate={fechaHoraSolicitudMinDate}
                  autoComplete="off"
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikDateTimePicker
                  name="fechaHoraTurno"
                  label={`Para el ${fields.fechaHoraTurno.required ? "*" : ""}`}
                  inputVariant="outlined"
                  className={classes.input}
                  disabled={disabled || disableDateUpdate}
                  minDate={
                    values.fechaHoraSolicitud
                      ? values.fechaHoraSolicitud
                      : undefined
                  }
                  autoComplete="off"
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikSelect
                  name="tipoTransporteNombre"
                  value={fptModel.tipoTransporteNombre}
                  label={`Tipo de transporte ${fields.tipoTransporteNombre.required ? "*" : ""
                    }`}
                  variant="outlined"
                  className={classes.input}
                  disabled={disabled}>
                  {fields.tipoTransporteNombre.options.map((ttn) => (
                    <MenuItem value={ttn.id} key={ttn.id}>
                      {ttn.nombre}
                    </MenuItem>
                  ))}
                </FormikSelect>
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikSelect
                  name="tipoUrgenciaNombre"
                  value={fptModel.tipoUrgenciaNombre}
                  label={`Tipo de urgencia ${fields.tipoUrgenciaNombre.required ? "*" : ""
                    }`}
                  variant="outlined"
                  className={classes.input}
                  disabled={disabled}>
                  {fields.tipoUrgenciaNombre.options.map((tun) => (
                    <MenuItem value={tun.id} key={tun.id}>
                      {tun.nombre}
                    </MenuItem>
                  ))}
                </FormikSelect>
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikAutocomplete
                  name="solicitadoPor"
                  label={`Solicitado por ${fields.solicitadoPor.required ? "*" : ""
                    }`}
                  variant="outlined"
                  options={fields.solicitadoPor.options}
                  className={classes.input}
                  disabled={disabled}
                />
              </div>
              <div className={classes.rightColumnContainerCheckBoxStart}>
                <FormikRadio
                  name="esperaRetorno"
                  label={`Espera y retorno ${fields.esperaRetorno.required ? "*" : ""
                    }`}
                  disabled={disabled}
                  row>
                  <FormControlLabel
                    value="Sí"
                    control={<Radio />}
                    label="Sí"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio />}
                    label="No"
                    labelPlacement="end"
                  />
                </FormikRadio>
              </div>
            </div>
            <div className={classes.rowContainer}>
              <FormikTextFieldSpeech
                id="observaciones"
                name="observaciones"
                label={`Observaciones ${fields.observaciones.required ? "*" : ""
                  }`}
                type="text"
                variant="outlined"
                multiline
                rows={3}
                rowsMax={10}
                className={classes.input}
                disabled={disabled}
              />
            </div>
            <DireccionFpt
              values={values}
              isDesde={true}
              fields={fields}
              disabled={disabled}
              setFieldValue={setFieldValue}
              idArt={idArt}
              direccionesHabituales={direccionesHabitualesDesde}
              setDireccionesHabitualesOtro={setDireccionesHabitualesHasta}
              initialValues={fptModel}
            />
            <DireccionFpt
              values={values}
              isDesde={false}
              fields={fields}
              disabled={disabled}
              setFieldValue={setFieldValue}
              idArt={idArt}
              direccionesHabituales={direccionesHabitualesHasta}
              setDireccionesHabitualesOtro={setDireccionesHabitualesDesde}
              initialValues={fptModel}
            />
            {model.configuration.showAttachments && (
              <div>
                <FormSectionDivider title="Adjuntos" />
                <div className={classes.rowContainer}>
                  <DropZone
                    files={files}
                    setFiles={setFiles}
                    configuration={model.configuration}
                    error={attachmentError}
                    setError={setAttachmentError}
                    disabled={disabled}
                    selectedDocument={selectedDocument}
                    accept={model.configuration.availableExtensions}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={classes.actionsContainer}>
            <FormikActions handleCancel={handleClose} disabled={disabled} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Fpt;
