import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import {
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    fontFamily: "Roboto, sans-serif",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "default",
  },
}));

export default function TermsConditionsDialog({ open, onClose }) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <h2 style={{ margin: -2 }}>Términos y Condiciones</h2>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description" align="justify">
          El presente contrato describe los términos y condiciones ("Términos y
          Condiciones Generales") aplicables al uso del sitio ML.Point y a los
          servicios ofrecidos por ACCESS INFORMATICA S.R.L., CUIT 30-68624792-5
          a través del mismo ("Servicios") dentro del sitio www.mlpoint.com.ar.
          Cualquier persona (en adelante "Usuario" o en plural "Usuarios") que
          desee acceder y/o usar el sitio y los Servicios podrá hacerlo
          sujetándose a los Términos y Condiciones respectivos, junto con todas
          las demás políticas y principios que rigen el servicio de Conectividad
          (ML.connect) que brinda ACCESS INFORMATICA S.R.L. y que son
          incorporados al presente por referencia.
          <br />
          <br />
          <b>
            CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES
            GENERALES, LOS CUALES TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE,
            DEBERÁ ABSTENERSE DE UTILIZAR EL SITIO Y/O LOS SERVICIOS.
          </b>
          <br />
          <br />
          El Usuario debe leer, entender y aceptar todas las condiciones
          establecidas en los Términos y Condiciones y en las Políticas de
          Privacidad así como en los demás documentos incorporados a los mismos
          por referencia.
          <br />
          <br />
          <b>01 - Capacidad</b>
          <br />
          <br />
          Los Servicios sólo están disponibles para personas que tengan
          capacidad legal para representar a los prestadores de salud a nombre
          de tal entidad y de obligar a la misma en los términos de este
          acuerdo. No podrán utilizar los servicios las personas que no tengan
          esa capacidad, los menores de edad o usuarios de ML.Point que hayan
          sido suspendidos temporalmente o inhabilitados definitivamente.
          <br />
          <br />
          <b>02 - Registración</b>
          <br />
          <br />
          Describir proceso de registración. Asume el compromiso de actualizar
          los datos conforme resulte necesario.
          <br />
          <br />
          El Usuario presta expresa conformidad con que ACCESS INFORMATICA
          S.R.L. utilice esos datos para comunicaciones relacionadas al
          Servicio, asumiendo el Usuario la obligación de revisarlos y
          mantenerlos actualizados. ACCESS INFORMATICA S.R.L. NO se
          responsabiliza por la certeza de los datos de los Usuarios. Los
          Usuarios garantizan y responden, en cualquier caso, de la veracidad,
          exactitud, vigencia y autenticidad de sus datos.
          <br />
          <br />
          ACCESS INFORMATICA S.R.L. se reserva el derecho de solicitar algún
          comprobante y/o dato adicional a efectos de corroborar los datos del
          usuario, así como de suspender temporal o definitivamente a aquellos
          Usuarios cuyos datos no hayan podido ser confirmados.
          <br />
          <br />
          El Usuario accederá al sitio mediante el ingreso de su nombre de
          usuario y clave de seguridad personal elegida ("Clave de Seguridad").
          El Usuario se obliga a mantener la confidencialidad de su Clave de
          Seguridad. Su acceso es personal, única e intransferible.
          <br />
          <br />
          El Usuario será responsable por todas las operaciones efectuadas con
          su usuario, pues el acceso a la misma está restringido al ingreso y
          uso de su Clave de Seguridad, de conocimiento exclusivo del Usuario.
          El Usuario se compromete a notificar a ACCESS INFORMATICA S.R.L. en
          forma inmediata y por medio idóneo y fehaciente, cualquier uso no
          autorizado de su usuario, así como el ingreso por terceros no
          autorizados a la misma. Se aclara que está prohibida la venta, cesión
          o transferencia del acceso bajo ningún título.
          <br />
          <br />
          ACCESS INFORMATICA S.R.L. se reserva el derecho de rechazar cualquier
          solicitud de registración o de cancelar una registración previamente
          aceptada, sin que esté obligado a comunicar o exponer las razones de
          su decisión y sin que ello genere algún derecho a indemnización o
          resarcimiento.
          <br />
          <br />
          <b>03 - Modificaciones del Acuerdo</b>
          <br />
          <br />
          Todo usuario que no esté de acuerdo con las modificaciones efectuadas
          por ACCESS INFORMATICA S.R.L. podrá solicitar la baja de la cuenta.
          ACCESS INFORMATICA S.R.L. podrá modificar los Términos y Condiciones
          Generales en cualquier momento haciendo públicos en el Sitio los
          términos modificados. Todos los términos modificados entrarán en vigor
          a los 10 (diez) días de su publicación.
          <br />
          <br />
          El uso del sitio y/o sus servicios implica la aceptación de estos
          Términos y Condiciones de uso de ACCESS INFORMATICA S.R.L..
          <br />
          <br />
          <b>04 – Uso del Sitio ML.Point</b>
          <br />
          <br />
          <i>4.1 Utilización de ACCESS INFORMATICA S.R.L.</i>
          <br />
          <br />
          Con el uso del Sitio, el usuario consiente expresamente la utilización
          de ML.Connect para la transmisión de novedades sobre Siniestros de ART
          para las ART´s conectadas a la plataforma. Asimismo, el usuario
          declara que ha leído, comprendido y aceptado los{" "}
          <u>Términos y Condiciones</u> de utilización de dicho servicio.
          <br />
          <br />
          <i>4.2 Carga de novedades</i>
          <br />
          <br />
          El Usuario deberá registrar las novedades sobre los Siniestros ARTs
          atendidos por el prestador al cual representa. Las novedades podrán
          incluir textos descriptivos, fechas, gráficos, fotografías y otros
          contenidos y condiciones pertinentes para la información del siniestro
          para la ART, siempre que no violen ninguna disposición de este acuerdo
          o demás políticas de ACCESS INFORMATICA S.R.L.. Se entiende y presume
          que mediante la carga de la novedad sobre el siniestro, el Usuario
          acepta que tiene la intención de informar a la ART y está facultado
          para ello por su titular.
          <br />
          <br />
          <i>4.3 Licencia sobre imágenes, fotografías, e Información</i>
          <br />
          <br />
          El usuario autoriza a ACCESS INFORMATICA S.R.L. a utilizar,
          transmitir, publicar, y reproducir la información, imágenes y
          fotografías incluidas en su carga. Será obligación del Usuario incluir
          la información, imágenes y fotografías de los siniestros atendidos, de
          manera veraz, actualizada e de acuerdo a las pautas y modalidad
          requeridas por la legislación aplicable para el tratamiento de
          Accidentes de Trabajo y Enfermedades Profesionales reglado por la Ley
          XXXX y concordantes. Conforme a lo anterior, ACCESS INFORMATICA S.R.L.
          podrá obtener la información, imágenes y fotografías directamente del
          Usuario, terceros autorizados por éste, o a través del sitio web del
          Usuario.
          <br />
          <br />
          En particular, el Usuario otorga a ACCESS INFORMATICA S.R.L. una
          autorización gratuita, irrevocable, no exclusiva, nacional y sin
          límite temporal para usar, publicar, y reproducir la información,
          imágenes y fotografías con el fin de ser usadas en todos los servicios
          de la plataforma ML.Connect y aplicaciones de ACCESS INFORMATICA
          S.R.L., y/o en cualquier medio no masivo de comunicación, incluyendo
          sin limitación, plataformas y cualquier otro medio digital o físico
          que ACCESS INFORMATICA S.R.L. considere oportuno o con aquellas otras
          plataformas o sitios de Internet con los cuales ACCESS INFORMATICA
          S.R.L. haya realizado una alianza, para potenciar, beneficiar o
          aportar a los servicios de ACCESS INFORMATICA S.R.L., así como
          sublicenciar su uso a terceros, incluyendo, de manera enunciativa, más
          no limitativa, facultades de uso, publicación y reproducción de la
          información, imágenes y fotografías.
          <br />
          <br />
          El Usuario declara y garantiza que es titular o licenciatario de los
          derechos necesarios sobre la información, imágenes y fotografías
          contenidas en las novedades de los siniestros incorporadas al sitio,
          siendo responsable exclusivo por cualquier infracción a derechos de
          terceros o por las inconsistencias o inexactitud en la Información de
          volcada.
          <br />
          <br />
          <b>
            ACCESS INFORMATICA S.R.L. podrá eliminar la publicación de la
            información, imágenes y fotografías, e incluso del siniestro, si
            interpretara, a su exclusivo criterio, que no cumple con los
            presentes Términos y Condiciones.
          </b>
          <br />
          <br />
          <i>4.4 Contenidos Prohibidos</i>
          <br />
          <br />
          Sólo podrán ser ingresados información, imágenes y fotografías que no
          se encuentren tácita o expresamente prohibida en los Términos y
          Condiciones y demás políticas de ACCESS INFORMATICA S.R.L. o por la
          ley vigente.
          <br />
          <br />
          <i>4.5 Brand Protection Program</i>
          <br />
          <br />
          En caso de que ACCESS INFORMATICA S.R.L. reciba una denuncia o un
          reclamo de un tercero o sospeche que se está cometiendo o se ha
          cometido una infracción, ACCESS INFORMATICA S.R.L. se reserva el
          derecho de adoptar todas las medidas que entienda adecuadas, lo que
          puede incluir la aplicación de sanciones sobre la cuenta y la
          información, imágenes y fotografías, como también brindar datos
          personales del usuario, tal como se describe en el presente.
          <br />
          <br />
          <b>05 - Privacidad de la Información</b>
          <br />
          <br />
          Para utilizar los Servicios ofrecidos por ACCESS INFORMATICA S.R.L.,
          los Usuarios deberán facilitar determinados datos de carácter
          personal. Su información personal se procesa y almacena en servidores
          o medios magnéticos que mantienen altos estándares de seguridad y
          protección tanto física como tecnológica.
          <br />
          <br />
          <b>06 - Obligaciones de los Usuarios</b>
          <br />
          <br />
          <i>6.2. Obligaciones del Usuario</i>
          <br />
          <br />
          El usuario debe tener capacidad legal para el uso y carga contemplado
          por el sitio. Asimismo, debe cumplir con todas las obligaciones
          regulatorias pertinentes y contar con los registros, habilitaciones,
          permisos y/o autorizaciones exigidos por la normativa aplicable.
          <br />
          <br />
          Dado que ACCESS INFORMATICA S.R.L. es un punto de encuentro entre el
          usuario Prestador y la ART o Gerenciadora de ART y no participa de las
          operaciones que se realizan entre ellos, el Prestador, ART y
          Gerenciadora de ART serán responsables por todas las obligaciones y
          cargas impositivas que correspondan por el tratamiento y gestión de
          Siniestros de ART, sin que pudiera imputársele a ACCESS INFORMATICA
          S.R.L. algún tipo de responsabilidad por incumplimientos en tal
          sentido.
          <br />
          <br />
          <b>Impuestos.</b> Como se menciona anteriormente, ACCESS INFORMATICA
          S.R.L. sólo pone a disposición de los usuarios un espacio virtual que
          les permite comunicarse mediante Internet para informar las novedades
          sobre los siniestros atendidos por estos. ACCESS INFORMATICA S.R.L. no
          tiene participación alguna en el proceso de negociación, atención,
          obligaciones y responsabilidades del contrato definitivo entre las
          partes. Por eso, ACCESS INFORMATICA S.R.L. no es responsable por el
          efectivo cumplimiento de las obligaciones establecidas por la ley
          vigente.
          <br />
          <br />
          <b>07 - Violaciones del Sistema o Bases de Datos</b>
          <br />
          <br />
          No está permitida ninguna acción o uso de dispositivo, software, u
          otro medio tendiente a interferir tanto en las actividades y
          operatoria de ML.Point como en cualquier servicio prestador por ACCESS
          INFORMATICA S.R.L.. Cualquier intromisión, tentativa o actividad
          violatoria o contraria a las leyes sobre derecho de propiedad
          intelectual y/o a las prohibiciones estipuladas en este contrato harán
          pasible a su responsable de las acciones legales pertinentes, y a las
          sanciones previstas por este acuerdo, así como lo hará responsable de
          indemnizar los daños ocasionados.
          <br />
          <br />
          <b>09 - Sanciones. Suspensión de operaciones</b>
          <br />
          <br />
          Sin perjuicio de otras medidas, ACCESS INFORMATICA S.R.L. podrá
          advertir, suspender en forma temporal o definitivamente la Cuenta de
          un Usuario, si (a) se quebrantara alguna ley, o cualquiera de las
          estipulaciones de los Términos y Condiciones y demás políticas de
          ACCESS INFORMATICA S.R.L.; (b) si incumpliera sus compromisos como
          Usuario; (c) si se incurriera a criterio de ACCESS INFORMATICA S.R.L.
          en conductas o actos dolosos o fraudulentos; (d) no pudiera
          verificarse la identidad del Usuario o cualquier información
          proporcionada por el mismo fuere errónea.
          <br />
          <br />
          <b>10 - Responsabilidad</b>
          <br />
          <br />
          ACCESS INFORMATICA S.R.L. sólo pone a disposición de los Usuarios un
          espacio virtual que les permite ponerse en comunicación mediante
          Internet para informar las novedades de siniestros de ART atendidos
          por estos. ACCESS INFORMATICA S.R.L. no participa en la atención ni
          gestión de los siniestros, ni ofrece servicios de salud. ACCESS
          INFORMATICA S.R.L. no interviene en el perfeccionamiento de las
          operaciones realizadas entre los Usuarios ni en las condiciones por
          ellos estipuladas para las mismas, por ello no será responsable
          respecto de la existencia, calidad, cantidad, estado, integridad o
          legitimidad de la prestación de los servicios ofrecidos y adquiridos y
          de la capacidad para contratar de los Usuarios o de la veracidad de la
          información por ellos ingresada. Cada Usuario conoce y acepta ser el
          exclusivo responsable por la información que publica.
          <br />
          <br />
          Debido a que ACCESS INFORMATICA S.R.L. no tiene ninguna participación
          durante todo el tiempo en que el bien o servicio se publica para la
          venta, ni en la posterior negociación y perfeccionamiento del contrato
          definitivo entre las partes, no será responsable por el efectivo
          cumplimiento de las obligaciones asumidas por los Usuarios en el
          perfeccionamiento de la operación. El Usuario conoce y acepta que al
          realizar operaciones con otros Usuarios o terceros lo hace bajo su
          propio riesgo. En ningún caso ACCESS INFORMATICA S.R.L. será
          responsable por lucro cesante, o por cualquier otro daño y/o perjuicio
          que haya podido sufrir el Usuario, debido a las operaciones realizadas
          o no realizadas por artículos publicados a través de ACCESS
          INFORMATICA S.R.L..
          <br />
          <br />
          En caso que uno o más Usuarios o algún tercero inicien cualquier tipo
          de reclamo o acciones legales contra otro u otros Usuarios, todos y
          cada uno de los Usuarios involucrados en dichos reclamos o acciones
          eximen de toda responsabilidad a ACCESS INFORMATICA S.R.L. y a sus
          gerentes, empleados, representantes y apoderados.
          <br />
          <br />
          <b>11 - Alcance de los servicios de ACCESS INFORMATICA S.R.L.</b>
          <br />
          <br />
          Este acuerdo no crea ningún contrato de sociedad, de servicios o
          relación laboral entre ACCESS INFORMATICA S.R.L. y el usuario. El
          usuario reconoce y acepta que ACCESS INFORMATICA S.R.L. no es parte en
          ninguna operación, ni tiene control alguno sobre la calidad, seguridad
          o legalidad de los servicios anunciados, la veracidad o exactitud del
          contenido y la capacidad de los Usuarios. ACCESS INFORMATICA S.R.L. no
          puede asegurar que un usuario completará una novedad ni podrá
          verificar su veracidad, identidad o datos personales ingresados por
          los usuarios. De igual forma, el usuario reconoce y acepta que ACCESS
          INFORMATICA S.R.L. no es parte y no interviene ni tiene control alguno
          sobre los servicios brindados por el sitio.
          <br />
          <br />
          <b>12 - Fallas en el sistema</b>
          <br />
          <br />
          ACCESS INFORMATICA S.R.L. no se responsabiliza por cualquier daño,
          perjuicio o pérdida al usuario causados por fallas en el sistema, en
          el servidor o en Internet. ACCESS INFORMATICA S.R.L. tampoco será
          responsable por cualquier virus que pudiera infectar el equipo del
          usuario como consecuencia del acceso, uso o examen de su sitio web o a
          raíz de cualquier transferencia de datos, archivos, imágenes, textos,
          o audio contenidos en el mismo. Los usuarios NO podrán imputarle
          responsabilidad alguna ni exigir pago por lucro cesante, en virtud de
          perjuicios resultantes de dificultades técnicas o fallas en los
          sistemas o en Internet. ACCESS INFORMATICA S.R.L. no garantiza el
          acceso y uso continuado o ininterrumpido de su sitio. El sistema puede
          eventualmente no estar disponible debido a dificultades técnicas o
          fallas de Internet, o por cualquier otra circunstancia ajena a ACCESS
          INFORMATICA S.R.L.; en tales casos se procurará restablecerlo con la
          mayor celeridad posible sin que por ello pueda imputársele algún tipo
          de responsabilidad. ACCESS INFORMATICA S.R.L. no será responsable por
          ningún error u omisión contenidos en su sitio web.
          <br />
          <br />
          <b>13 – Costo del Servicio</b>
          <br />
          <br />
          ACCESS INFORMATICA S.R.L. se reserva el derecho de modificar, cambiar,
          agregar, o eliminar tarifas para el servicio del sitio ML.Point, en
          cualquier momento, lo cual será notificado a los Usuarios.
          <br />
          <br />
          <b>14 – Autenticación del Usuario</b>
          <br />
          <br />
          Debido a que la verificación de la identidad de los Usuarios en
          Internet es difícil, ACCESS INFORMATICA S.R.L. no puede confirmar ni
          confirma la identidad pretendida de cada Usuario.
          <br />
          <br />
          <b>15 - Propiedad intelectual - Licencia - Enlaces</b>
          <br />
          <br />
          ACCESS INFORMATICA S.R.L. se reserva todos los derechos, incluyendo
          los derechos de propiedad intelectual e industrial, asociados con los
          servicios de ML.Point, sus sitios web, los contenidos de sus
          pantallas, programas, bases de datos, redes, códigos, desarrollo,
          software, arquitectura, hardware, contenidos, información, tecnología,
          fases de integración, funcionalidades, dominios, archivos que permiten
          al Usuario acceder y crear su Cuenta, marcas, patentes, derechos de
          autor, diseños y modelos industriales, nombres comerciales, entre
          otros, y declara que están protegidos por leyes nacionales e
          internacionales vigentes.
          <br />
          <br />
          En ningún caso se entenderá que el Usuario tendrá algún tipo de
          derecho sobre los mismos excepto para utilizar el servicio de ACCESS
          INFORMATICA S.R.L. conforme a lo previsto en estos Términos y
          Condiciones. El uso indebido o contrario a la normativa vigente de los
          derechos de propiedad intelectual e industrial de ACCESS INFORMATICA
          S.R.L., así como su reproducción total o parcial, queda prohibido,
          salvo autorización expresa y por escrito de ACCESS INFORMATICA S.R.L..
          <br />
          <br />
          Los Usuarios tampoco podrán comunicar que los servicios que ofrecen
          son patrocinados, promovidos, producidos, ofrecidos y/o vendidos por
          ACCESS INFORMATICA S.R.L. y deberán abstenerse de realizar cualquier
          acto que pudiera causar un daño, pérdida de reputación, o disminución
          del valor de los derechos de propiedad intelectual e industrial de
          ACCESS INFORMATICA S.R.L..
          <br />
          <br />
          El sitio puede contener enlaces a sitios web de terceros. En virtud
          que ACCESS INFORMATICA S.R.L. no tiene control sobre tales sitios, no
          será responsable por los contenidos, materiales, acciones y/o
          servicios prestados por los mismos, ni por daños o pérdidas
          ocasionadas por la utilización de éstos, causados directa o
          indirectamente. La presencia de enlaces a otros sitios web no implica
          una sociedad, relación, aprobación, respaldo de ACCESS INFORMATICA
          S.R.L. con dichos sitios y sus contenidos.
          <br />
          <br />
          <b>16 - Indemnidad</b>
          <br />
          <br />
          El Usuario mantendrá indemne a ACCESS INFORMATICA S.R.L., por
          cualquier reclamo iniciado por otros Usuarios, terceros o por
          cualquier Organismo, relacionado con sus actividades en el Sitio, el
          cumplimiento y/o el incumplimiento de los Términos y Condiciones o
          demás Políticas, así como respecto de cualquier violación de leyes o
          derechos de terceros.
          <br />
          <br />
          <b>18 - Jurisdicción y Ley Aplicable</b>
          <br />
          <br />
          Este acuerdo estará regido en todos sus puntos por las leyes vigentes
          en la República Argentina.
          <br />
          <br />
          Cualquier controversia derivada del presente acuerdo, su existencia,
          validez, interpretación, alcance o cumplimiento, será sometida ante la
          Justicia Nacional Ordinaria.
          <br />
          <br />
          <b>19 - Domicilio</b>
          <br />
          <br />
          Se fija como domicilio de ACCESS INFORMATICA S.R.L. en Malabia 429
          Piso 4 CABA, República Argentina.
          <br />
          <br />
          Si tienes alguna duda sobre los Términos y Condiciones o demás
          políticas y principios que rigen ACCESS INFORMATICA S.R.L. envíanos un
          mail a info@accessinformatica.com.ar.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={onClose} color="primary" variant="contained">
          Al usar la aplicación acepto los términos y condiciones
        </Button>
      </DialogActions>
    </Dialog>
  );
}
