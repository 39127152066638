import { MenuItem } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { getFechaMasReciente } from "../../../../utils/datetimeUtils";
import FormikActions from "../../../commons/formik/formikActions";
import FormikCheckbox from "../../../commons/formik/formikCheckbox";
import FormikDateTimePicker from "../../../commons/formik/formikDateTimePicker";
import FormikSelect from "../../../commons/formik/formikSelect";
import FormikTextFieldSpeech from "../../../commons/formik/formikTextFieldSpeech";
import DropZone from "../dropZone/dropZone";
import FormSectionDivider from "../formSectionDivider";
import { useStyles } from "../formStyle";
import { fpaValidation, validateRequiredFields } from "../formValidation";
import ProfessionalControl from "../professionalControl/professionalControl";
import PrestacionesContainer from "./prestacionesContainer";

const Fpa = ({
  handleClose,
  files,
  setFiles,
  handleSubmit,
  model,
  fpaModel,
  roles,
  prestaciones,
  setPrestaciones,
  selectedDocument,
  fromErrorDialog,
  fromErroresConectividad,
  isView,
}) => {
  const classes = useStyles();

  const [attachmentError, setAttachmentError] = useState(false);
  const [professionalError, setProfessionalError] = useState(false);

  const fields = model.fields;
  const disabled =
    roles && !fromErrorDialog && !fromErroresConectividad
      ? !roles.editar
      : false;

  const fechaHoraPedidoMinDate =
    fields.fechaHoraPedido.validations &&
    fields.fechaHoraPedido.validations.minDate
      ? getFechaMasReciente(
          fields.fechaHoraPedido.validations.minDate,
          fields.fechaPrimeraCuracion.options,
        )
      : fields.fechaPrimeraCuracion.options;

  return (
    <Formik
      initialValues={fpaModel}
      validationSchema={() => fpaValidation(fechaHoraPedidoMinDate)}
      validate={(values) => validateRequiredFields(values, fields)}
      onSubmit={(values) => {
        if (model.configuration.requiresAttachment && files.length === 0) {
          setAttachmentError(true);
        } else if (
          !fields.prestaciones.validations.prestadorPrestacional &&
          model.configuration.minAmountOfPrestaciones !== 0 &&
          prestaciones.length < model.configuration.minAmountOfPrestaciones
        ) {
          //ya estoy mostrando el error
        } else if (!professionalError) {
          handleSubmit(values);
        }
      }}>
      {({ values, setFieldValue }) => (
        <Form className={classes.container}>
          <div className={classes.fieldContainer}>
            <FormSectionDivider title="Autorización" />
            {model.configuration.showTypeFormCombo && (
              <div className={classes.rowContainer}>
                <FormikSelect
                  name="nombreTipoFormulario"
                  label={`Tipo de Formulario Especifico ${
                    fields.nombreTipoFormulario.required ? "*" : ""
                  }`}
                  value={fpaModel.nombreTipoFormulario}
                  variant="outlined"
                  className={classes.input}
                  disabled={disabled}>
                  {fields.tiposFormulario.options.map((f) => {
                    return (
                      <MenuItem key={f.nombre} value={f.nombre}>
                        {f.formulario} - {f.nombre}
                      </MenuItem>
                    );
                  })}
                </FormikSelect>
              </div>
            )}
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikDateTimePicker
                  name="fechaHoraPedido"
                  label={`Fecha Hora ${
                    fields.fechaHoraPedido.required ? "*" : ""
                  }`}
                  disableFuture
                  inputVariant="outlined"
                  className={classes.input}
                  disabled={disabled}
                  minDate={fechaHoraPedidoMinDate}
                  autoComplete="off"
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikCheckbox
                  name="enInstitucionPropia"
                  label={`A realizarse en esta institución ${
                    fields.enInstitucionPropia.required ? "*" : ""
                  }`}
                  labelPlacement="end"
                  disabled={disabled}
                />
              </div>
            </div>

            <div className={classes.rowContainer}>
              <FormikTextFieldSpeech
                id="detalleAutorizacion"
                name="detalleAutorizacion"
                label={`Detalle autorización ${
                  fields.detalleAutorizacion.required ? "*" : ""
                }`}
                type="text"
                variant="outlined"
                multiline
                rows={3}
                rowsMax={10}
                className={classes.input}
                disabled={disabled}
              />
            </div>
            <div className={classes.rowContainer}>
              <FormikTextFieldSpeech
                id="planTerapeutico"
                name="planTerapeutico"
                label={`Plan terapéutico ${
                  fields.planTerapeutico.required ? "*" : ""
                }`}
                type="text"
                variant="outlined"
                multiline
                rows={3}
                rowsMax={10}
                className={classes.input}
                disabled={disabled}
              />
            </div>
            <FormSectionDivider title="Diagnóstico" />
            <div className={classes.rowContainer}>
              <FormikTextFieldSpeech
                id="diagnostico"
                name="diagnostico"
                label={`Diagnóstico ${fields.diagnostico.required ? "*" : ""}`}
                type="text"
                variant="outlined"
                multiline
                rows={3}
                rowsMax={10}
                className={classes.input}
                disabled={disabled}
              />
            </div>
            {model.configuration.showPrestaciones &&
              !fields.prestaciones.validations.prestadorPrestacional && (
                <div>
                  <FormSectionDivider title="Prestaciones" />
                  <div className={classes.rowContainer}>
                    <PrestacionesContainer
                      prestaciones={prestaciones}
                      setPrestaciones={setPrestaciones}
                      disabled={roles}
                      configuration={model.configuration}
                    />
                  </div>
                </div>
              )}
            <FormSectionDivider title="Profesional" />
            <ProfessionalControl
              disabled={disabled}
              fields={fields}
              setFieldValue={setFieldValue}
              profesionalPreCarga={fpaModel.profesionalApellidoNombre}
              error={professionalError}
              setError={setProfessionalError}
              especialidadesProfesional={fields.especialidadProfesional}
              values={values}
              habilitarEspecialidadInforme={true}
              isView={isView}
            />
            {model.configuration.showAttachments && (
              <div>
                <FormSectionDivider title="Adjuntos" />
                <div className={classes.rowContainer}>
                  <DropZone
                    files={files}
                    setFiles={setFiles}
                    configuration={model.configuration}
                    error={attachmentError}
                    setError={setAttachmentError}
                    disabled={disabled}
                    selectedDocument={selectedDocument}
                    accept={model.configuration.availableExtensions}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={classes.actionsContainer}>
            <FormikActions handleCancel={handleClose} disabled={disabled} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Fpa;
