import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import { Block, CheckCircle, Clear, Visibility } from "@material-ui/icons";
import MaterialTable, { MTableToolbar } from "material-table";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getConfidentialFields } from "../../../actions/fields";
import { getFormatoFechaExportacion } from "../../../utils/datetimeUtils";
import { downloadCsv } from "../../../utils/filesUtils";
import snackbarUtils from "../../../utils/snackbarUtils";
import { redactFromRegistroConfidentialFields } from "../../../utils/util";
import { localization, tableStyles } from "./config/tableConfig";
import TitleContainer from "./utilsTable/titleWithFetchData";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
    marginLeft: 24,
  },
}));

const MaterialTableComponentt = ({
  data,
  loading,
  time,
  handleChangeTime,
  fetchData,
  title,
  noRefresh,
  cancelledAction,
  attendedAction,
  unAttendedAction,
  handleAccidentDetail,
  displayAccidents,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);

  const columnsSearch = [
    {
      title: title === "Turnos" && "Estado",
      field: "estado",
    },
    {
      title: "Fecha",
      field: "fechaHora",
      customFilterAndSearch: (term, rowData) => {
        if (moment(rowData.fechaHora).format("DD/MM/YYYY").includes(term))
          return true;
        return false;
      },
      render: (rowData) => moment(rowData.fechaHora).format("DD/MM/YYYY"),
    },
    {
      title: "Hora",
      field: "fechaHora",
      customFilterAndSearch: (term, rowData) => {
        if (moment(rowData.fechaHora).format("HH:mm").includes(term))
          return true;
        return false;
      },
      render: (rowData) => moment(rowData.fechaHora).format("HH:mm"),
    },
    {
      title: "Profesional",
      field: "profesional",
    },
    {
      title: "Especialidad",
      field: "especialidad",
    },
    {
      title: "Nro. Siniestro",
      field: "nroSiniestro",
      render: (rowData) => rowData.nroSiniestro.trim(),
    },
    {
      title: "Trabajador",
      field: "trabajador",
      customFilterAndSearch: (term, rowData) => {
        if (
          rowData.apellidoTrabajador
            .toLowerCase()
            .includes(term.toLowerCase()) ||
          rowData.nombreTrabajador.toLowerCase().includes(term.toLowerCase())
        )
          return true;
        return false;
      },
      render: (rowData) => {
        if (!rowData.nombreTrabajador) {
          return rowData.apellidoTrabajador;
        } else if (!rowData.apellidoTrabajador) {
          return rowData.nombreTrabajador;
        } else {
          return rowData.apellidoTrabajador + ", " + rowData.nombreTrabajador;
        }
      },
    },
    { title: "Documento", field: "nroDocumento" },
    { title: "Observaciones", field: "observaciones" },
    {
      render: (rowData) => (
        <Tooltip
          title="Ver detalles"
          onClick={() => handleAccidentDetail(rowData)}>
          <Visibility style={{ cursor: "pointer" }} />
        </Tooltip>
      ),
    },
  ];

  const columnsPending = [
    {
      title: "Art",
      field: "art",
      render: (rowData) => rowData.art.toUpperCase(),
    },
    {
      title: "Fecha",
      field: "fechaHora",
      customFilterAndSearch: (term, rowData) => {
        if (moment(rowData.fechaHora).format("DD/MM/YYYY").includes(term))
          return true;
        return false;
      },
      render: (rowData) => moment(rowData.fechaHora).format("DD/MM/YYYY"),
    },
    {
      title: "Hora",
      field: "fechaHora",
      customFilterAndSearch: (term, rowData) => {
        if (moment(rowData.fechaHora).format("HH:mm").includes(term))
          return true;
        return false;
      },
      render: (rowData) => moment(rowData.fechaHora).format("HH:mm"),
    },
    {
      title: "Profesional",
      field: "profesional",
    },
    {
      title: "Especialidad",
      field: "especialidad",
    },
    {
      title: "Siniestro",
      field: "nroSiniestro",
      render: (rowData) => rowData.nroSiniestro.trim(),
    },
    {
      title: "Trabajador",
      field: "trabajador",
      customFilterAndSearch: (term, rowData) => {
        if (
          rowData.apellidoTrabajador
            .toLowerCase()
            .includes(term.toLowerCase()) ||
          rowData.nombreTrabajador.toLowerCase().includes(term.toLowerCase())
        )
          return true;
        return false;
      },
      render: (rowData) => {
        if (!rowData.nombreTrabajador) {
          return rowData.apellidoTrabajador;
        } else if (!rowData.apellidoTrabajador) {
          return rowData.nombreTrabajador;
        } else {
          return rowData.apellidoTrabajador + ", " + rowData.nombreTrabajador;
        }
      },
    },
    { title: "Documento", field: "nroDocumento" },
    { title: "Observaciones", field: "observaciones" },
    {
      render: (rowData) => (
        <Visibility
          style={{ cursor: "pointer" }}
          onClick={() => handleAccidentDetail(rowData)}
        />
      ),
    },
  ];

  const formatTableDataForExport = (table) => {
    const tableDataWithFormat = table.map((fila) => [
      fila.art ? `"${fila.art}"` : "",
      fila.estado ? `"${fila.estado}"` : "",
      getFormatoFechaExportacion(fila.fechaHora, "dd/MM/yyyy"),
      getFormatoFechaExportacion(fila.fechaHora, "HH:mm"),
      fila.profesional ? `"${fila.profesional}"` : "",
      fila.especialidad ? `"${fila.especialidad}"` : "",
      fila.nroSiniestro ? `"${fila.nroSiniestro}"` : "",
      fila.nombreTrabajador + " " + fila.apellidoTrabajador,
      fila.nroDocumento ? `"${fila.nroDocumento}"` : "",
      fila.observaciones ? `"${fila.observaciones}"` : "",
    ]);

    return tableDataWithFormat;
  };

  const handleExportToCsv = (data) => {
    setIsDownloadingCsv(true);

    dispatch(getConfidentialFields("turnos"))
      .then((res) => {
        const redactedData = data.map((row) => {
          return redactFromRegistroConfidentialFields(
            row,
            res.value.data,
            "mlCloudCoreArtId",
          );
        });

        const encabezado = [
          "Art",
          "Estado",
          "Fecha",
          "Hora",
          "Profesional",
          "Especialidad",
          "Siniestro",
          "Trabajador",
          "Documento",
          "Observaciones",
        ];

        const formattedDataAsCsv = [
          encabezado,
          ...formatTableDataForExport(redactedData),
        ];

        let csvContent = "sep=,\n";
        csvContent += `${formattedDataAsCsv
          .map((e) => e.join(","))
          .join("\n")}`;

        downloadCsv(csvContent, "Turnos");
        snackbarUtils.success("Los turnos han sido descargados con éxito.");
        setIsDownloadingCsv(false);
      })
      .catch((err) => {
        snackbarUtils.error(err);
        setIsDownloadingCsv(false);
      });
  };

  return (
    <div>
      <MaterialTable
        columns={
          title === "Asistencias pendientes de informar"
            ? columnsPending
            : columnsSearch
        }
        data={data}
        isLoading={loading || isDownloadingCsv}
        style={{ ...tableStyles.container, display: displayAccidents }}
        title={
          <TitleContainer
            title={title}
            noRefresh={noRefresh}
            fetchData={fetchData}
          />
        }
        options={{
          showTextRowsSelected: true,
          actionsColumnIndex: -1,
          selection: title === "Asistencias pendientes de informar",
          headerStyle: tableStyles.headerStyle,
          rowStyle: tableStyles.rowStyle,
          pageSize: 10,
          search: false,
          filtering: true,
          exportButton: true,
          exportCsv: (columns, dataExport) => {
            handleExportToCsv(dataExport);
          },
        }}
        actions={
          title === "Asistencias pendientes de informar"
            ? [
                {
                  tooltip: "Concurrio",
                  icon: () => <CheckCircle color="secondary" />,
                  onClick: (evt, appointment) => {
                    appointment.forEach((item) => attendedAction(item));
                  },
                },
                {
                  tooltip: "No concurrio",
                  icon: () => <Block style={{ color: "#f14411" }} />,
                  onClick: (evt, appointment) => {
                    appointment.forEach((item) => unAttendedAction(item));
                  },
                },
                {
                  tooltip: "Anular",
                  icon: () => <Clear style={{ color: "#ca0e00" }} />,
                  onClick: (evt, appointment) => {
                    appointment.forEach((item) => cancelledAction(item));
                  },
                },
              ]
            : null
        }
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              {time && (
                <div>
                  <FormControl className={classes.formControl}>
                    <InputLabel>Duración</InputLabel>
                    <Select onChange={handleChangeTime} value={time}>
                      <MenuItem value={2}>48 horas</MenuItem>
                      <MenuItem value={7}>7 días</MenuItem>
                      <MenuItem value={30}>30 días</MenuItem>
                      <MenuItem value={100}>100 días</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              )}
            </div>
          ),
        }}
        localization={{
          ...localization,
          body: {
            emptyDataSourceMessage: "No hay asistencias que mostrar",
            filterRow: {
              filterTooltip: "Filtrar",
            },
          },
        }}
      />
    </div>
  );
};

export default MaterialTableComponentt;
