import { Button, Grid, MenuItem, makeStyles } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import { Form, Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { downloadIetFile } from "../../../../../actions/importForms";
import { downloadFile } from "../../../../../utils/filesUtils";
import FormikSelect from "../../../../commons/formik/formikSelect";
import FeatureLabel from "../../../../commons/labels/FeatureLabel";
import DropZone from "../../../forms/dropZone/dropZone";

const validationSchema = Yup.object({
  artId: Yup.string().required("Campo obligatorio"),
});

const initialValues = {
  artId: "",
};

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
  },
  input: {
    marginRight: 20,
    width: "100%",
  },
  button: {
    margin: theme.spacing(2.5),
  },
  dropzone: {
    display: "flex",
    alignItems: "stretch",
    height: "20em",
  },
}));

const ImportIetForm = ({ handleSubmit, setFile, file }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const arts = useSelector((state) => state.art.artsAdhered);

  const handleDownloadFile = async () => {
    await dispatch(downloadIetFile()).then((res) => {
      downloadFile(res.value.data, "ArchivoEjemploImportarIET.csv");
    });
  };

  return (
    <div className={classes.formContainer}>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}>
        <Form>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <FormikSelect
                name="artId"
                value={initialValues.artId}
                label="Art"
                className={classes.input}>
                {arts.map((art) => (
                  <MenuItem value={art.id} key={art.id}>
                    {art.nombre}
                  </MenuItem>
                ))}
              </FormikSelect>
            </Grid>

            <Grid item xs={12}>
              <div className={classes.dropzone}>
                <DropZone
                  files={file}
                  setFiles={setFile}
                  loading={false}
                  configuration={{
                    oneFile: true,
                    fileMinSizeInBytes: 1,
                    typifyAttachmentFile: false,
                  }}
                  extension=".csv"
                  accept={{
                    "application/csv": [".csv"],
                  }}
                  dropzoneText="Arrastre el formulario en formato '.csv' aquí o haga click en el recuadro para
    adjuntarlo."
                />
              </div>
            </Grid>

            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-start" }}>
              <FeatureLabel
                labelText="ARCHIVO MODELO"
                icon={<GetApp />}
                onClick={handleDownloadFile}
                tooltipText="Descargar archivo"
              />
            </Grid>

            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                className={classes.button}
                type="submit"
                color="secondary"
                // file es el array de archivos adjuntos
                disabled={!file || file.length == 0}>
                Procesar
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </div>
  );
};

export default ImportIetForm;
