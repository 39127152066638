import { Container, makeStyles } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccidentsPerMonth,
  getAverageAttentionsNumber,
  getAverageTreatmentDays,
  getAvgAuthAnswerDaysPerQuarter,
  getCitationFrequenciesAverage,
  getPrevalenceRate,
} from "../../../actions/dashboard";
import CardContainer from "../../commons/dashboard/cardContainer";
import MyResponsiveBar from "../../commons/dashboard/myResponsiveBar";
import MyResponsiveBarMonths from "../../commons/dashboard/myResponsiveBarMonths";
import MyResponsiveLine from "../../commons/dashboard/myResponsiveLine";

const useStyles = makeStyles({
  title: {
    display: "flex",
    height: "40px",
    margin: "13px",
    padding: "10px 10px 10px 20px",
    color: "#808080d1",
    backgroundColor: "#80808029",
    borderRadius: "1px",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 500,
    fontSize: "17px",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: `repeat(auto-fill, minmax(500px, 1fr))`,
    gridGap: "10px",
  },
});

const StatisticDashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const accidentsPerMonth = useSelector(
    (state) => state.dashboard.accidentsPerMonth,
  );
  const citationFrequenciesAverage = useSelector(
    (state) => state.dashboard.citationFrequenciesAverage,
  );
  const averageTreatmentDays = useSelector(
    (state) => state.dashboard.averageTreatmentDays,
  );
  const avgAuthAnswerDaysPerQuarter = useSelector(
    (state) => state.dashboard.avgAuthAnswerDaysPerQuarter,
  );
  const prevalenceRate = useSelector((state) => state.dashboard.prevalenceRate);
  const averageAttentionsNumber = useSelector(
    (state) => state.dashboard.averageAttentionsNumber,
  );
  const keys = useSelector((state) => state.art.namesArtsArray);

  return (
    <Container
      style={{
        backgroundColor: "#f5f6fa",
      }}>
      <div className={classes.title}>ESTADÍSTICAS</div>

      <div className={classes.grid}>
        <CardContainer
          text="SINIESTROS INGRESADOS POR MES POR ART Y TOTALIZADO"
          component={
            <MyResponsiveBarMonths
              data={accidentsPerMonth.data}
              keys={keys}
              type="date"
            />
          }
          isFetching={accidentsPerMonth.isFetching}
          fetchData={() => dispatch(getAccidentsPerMonth())}
          didInvalidate={accidentsPerMonth.didInvalidate}
          name="accidentsPerMonth"
        />

        <CardContainer
          text="PROMEDIO DE FRECUENCIA DE CITACIONES"
          component={
            <MyResponsiveBar
              data={citationFrequenciesAverage.data}
              keys={keys}
              groupMode="grouped"
              type="date"
              name="quarter"
            />
          }
          isFetching={citationFrequenciesAverage.isFetching}
          fetchData={() => dispatch(getCitationFrequenciesAverage())}
          didInvalidate={citationFrequenciesAverage.didInvalidate}
          name="citationFrequenciesAverage"
        />

        <CardContainer
          text="DIAS DE TRATAMIENTO POR TRIMESTRE Y ART"
          component={
            <MyResponsiveBar
              data={averageTreatmentDays.data}
              keys={keys}
              groupMode="grouped"
              type="date"
              name="quarter"
            />
          }
          isFetching={averageTreatmentDays.isFetching}
          fetchData={() => dispatch(getAverageTreatmentDays())}
          didInvalidate={averageTreatmentDays.didInvalidate}
          name="averageTreatmentDays"
        />

        <CardContainer
          text="DIAS PROMEDIO RESPUESTA AUTORIZACIONES POR TRIMESTRE Y ART"
          component={
            <MyResponsiveBar
              data={avgAuthAnswerDaysPerQuarter.data}
              keys={keys}
              groupMode="grouped"
              type="date"
              name="quarter"
            />
          }
          isFetching={avgAuthAnswerDaysPerQuarter.isFetching}
          fetchData={() => dispatch(getAvgAuthAnswerDaysPerQuarter())}
          didInvalidate={avgAuthAnswerDaysPerQuarter.didInvalidate}
          name="avgAuthAnswerDaysPerQuarter"
        />

        <CardContainer
          text="ÍNDICE DE PREVALENCIA"
          component={<MyResponsiveLine data={prevalenceRate.data} />}
          isFetching={prevalenceRate.isFetching}
          fetchData={() => dispatch(getPrevalenceRate())}
          didInvalidate={prevalenceRate.didInvalidate}
          name="prevalenceRate"
        />

        <CardContainer
          text="CANTIDAD DE ATENCIONES PROMEDIO POR SINIESTRO POR TRIMESTRE Y ART"
          component={<MyResponsiveLine data={averageAttentionsNumber.data} />}
          isFetching={averageAttentionsNumber.isFetching}
          fetchData={() => dispatch(getAverageAttentionsNumber())}
          didInvalidate={averageAttentionsNumber.didInvalidate}
          name="averageAttentionsNumber"
        />
      </div>
    </Container>
  );
};

export default StatisticDashboard;
