import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedAccident } from "../../../../actions/accidentDashboard";
import { getAnsweredAuthorizations } from "../../../../actions/authorizations";
import AnsweredAuthorizationsTable from "../../../commons/table/answeredAuthorizationsTable";
import AccidentDashboardContainer from "../../accident/accidentDashboard/accidentDashboardContainer";

const AnsweredContainer = () => {
  const dispatch = useDispatch();

  const answeredAuthorizations = useSelector(
    (state) => state.answeredAuthorizations,
  );
  const namesArts = useSelector((state) => state.art.shortNameArt);

  const [time, setTime] = useState(2);
  const [displayAccidents, setDisplayAccidents] = useState("block");

  useEffect(() => {
    dispatch(getAnsweredAuthorizations(time));
  }, []);

  const handleChange = (event) => {
    setTime(event.target.value);
    dispatch(getAnsweredAuthorizations(event.target.value));
  };

  const handleAccidentDetail = (accident) => {
    dispatch(
      setSelectedAccident({
        ...accident,
        mlCloudCoreArtId: accident.artMLCloud,
        idSiniestro: accident.siniestroId,
      }),
    );
    setDisplayAccidents("none");
  };

  return (
    <div>
      {displayAccidents === "none" && (
        <AccidentDashboardContainer setDisplayAccidents={setDisplayAccidents} />
      )}
      <AnsweredAuthorizationsTable
        data={answeredAuthorizations.list}
        namesArts={namesArts}
        loading={answeredAuthorizations.isFetching}
        error={answeredAuthorizations.didInvalidate}
        isDetailLoading={answeredAuthorizations.isFetchingDetail}
        fetchData={() => dispatch(getAnsweredAuthorizations(time))}
        handleChangeTime={handleChange}
        time={time}
        title="Respondidas"
        isSearch={false}
        handleAccidentDetail={handleAccidentDetail}
        displayAccidents={displayAccidents}
      />
    </div>
  );
};

export default AnsweredContainer;
