import MaterialTable from "material-table";
import React, { useState } from "react";
import ArtInProvider from "../../ui/providers/artInProvider";
import { localization, tableStyles } from "./config/tableConfig";
import TitleContainer from "./utilsTable/titleWithFetchData";

const ProvidersTable = ({
  data,
  loading,
  fetchData,
  handleOpenEdit,
  handleDelete,
}) => {
  const [columnObject] = useState([
    {
      title: "Razón social",
      field: "nombre",
      render: (rowData) => rowData.nombre,
    },
  ]);

  return (
    <MaterialTable
      columns={columnObject}
      data={data}
      isLoading={loading}
      style={tableStyles.container}
      locale="es"
      title={<TitleContainer title="Prestadores" fetchData={fetchData} />}
      options={{
        actionsColumnIndex: -1,
        rowStyle: tableStyles.rowStyle,
        headerStyle: tableStyles.headerStyle,
        pageSize: 10,
        search: false,
        filtering: true,
        exportButton: false,
      }}
      actions={[
        {
          icon: "edit",
          tooltip: "Editar Prestador",
          onClick: (event, rowData) => handleOpenEdit(rowData),
        },
        {
          icon: "delete",
          tooltip: "Eliminar Prestador",
          onClick: (event, rowData) => handleDelete(rowData),
        },
      ]}
      localization={{
        ...localization,
        body: {
          emptyDataSourceMessage: "No hay Prestadores que mostrar",
          filterRow: {
            filterTooltip: "Filtrar",
          },
        },
      }}
      detailPanel={[
        {
          tooltip: "Más Información",
          render: (rowData) => (
            <div style={{ backgroundColor: "#fafafa", padding: 10 }}>
              <ArtInProvider provider={rowData} refresh={fetchData} />
            </div>
          ),
        },
      ]}
    />
  );
};
export default ProvidersTable;
