import { CircularProgress, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccidentsPerMonth,
  getAverageAttentionsNumber,
  getAverageTreatmentDays,
  getAvgAuthAnswerDaysPerQuarter,
  getCitationFrequenciesAverage,
  getPrevalenceRate,
} from "../../../actions/dashboard";
import StatisticDashboard from "./statisticDashboard";

const useStyles = makeStyles({
  lodash: {
    justifyContent: "center",
    marginTop: "230px",
    textAlign: "center",
  },
});

const StatisticContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const accidentsPerMonth = useSelector(
    (state) => state.dashboard.accidentsPerMonth,
  );
  const citationFrequenciesAverage = useSelector(
    (state) => state.dashboard.citationFrequenciesAverage,
  );
  const averageTreatmentDays = useSelector(
    (state) => state.dashboard.averageTreatmentDays,
  );
  const avgAuthAnswerDaysPerQuarter = useSelector(
    (state) => state.dashboard.avgAuthAnswerDaysPerQuarter,
  );
  const prevalenceRate = useSelector((state) => state.dashboard.prevalenceRate);
  const averageAttentionsNumber = useSelector(
    (state) => state.dashboard.averageAttentionsNumber,
  );

  const namesArtsArray = useSelector((state) => state.art.namesArtsArray);

  useEffect(() => {
    if (!accidentsPerMonth.isFetching && !accidentsPerMonth.data.length)
      dispatch(getAccidentsPerMonth());
    if (!averageTreatmentDays.isFetching && !averageTreatmentDays.data.length)
      dispatch(getAverageTreatmentDays());
    if (
      !avgAuthAnswerDaysPerQuarter.isFetching &&
      !avgAuthAnswerDaysPerQuarter.data.length
    )
      dispatch(getAvgAuthAnswerDaysPerQuarter());
    if (!prevalenceRate.isFetching && !prevalenceRate.data.length)
      dispatch(getPrevalenceRate());
    if (
      !averageAttentionsNumber.isFetching &&
      !averageAttentionsNumber.data.length
    )
      dispatch(getAverageAttentionsNumber());
    if (
      !citationFrequenciesAverage.isFetching &&
      !citationFrequenciesAverage.data.length
    )
      dispatch(getCitationFrequenciesAverage());
  }, []);

  return (
    <div style={{ backgroundColor: "#f5f6fa" }}>
      {namesArtsArray.length ? (
        <StatisticDashboard />
      ) : (
        <div className={classes.lodash}>
          <CircularProgress size={40} />
        </div>
      )}
    </div>
  );
};

export default StatisticContainer;
