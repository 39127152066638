import MaterialTable from "material-table";
import React from "react";
import { useSelector } from "react-redux";
import { localization, tableStyles } from "./config/tableConfig";
import TitleContainer from "./utilsTable/titleWithFetchData";

const ProviderInUserTable = ({ data, handleOpenEdit, handleDelete }) => {
  const esSoporte = useSelector(
    (state) => state.auth.roles?.tipo === "Soporte",
  );

  const columns = [
    {
      title: "Prestador",
      field: "nombre",
    },
  ];

  return (
    <MaterialTable
      columns={columns}
      data={data}
      style={{ ...tableStyles.container, backgroundColor: "#f7faff" }}
      locale="es"
      title={<TitleContainer title="Prestadores" />}
      options={{
        actionsColumnIndex: -1,
        rowStyle: tableStyles.rowStyle,
        headerStyle: { ...tableStyles.headerStyle, backgroundColor: "#f7faff" },
        pageSize: 5,
        search: false,
        filtering: true,
        exportButton: false,
      }}
      actions={[
        // {
        //   icon: "edit",
        //   tooltip: "Editar Usuario",
        //   onClick: (event, rowData) => handleOpenEdit(rowData),
        // },
        {
          icon: "delete",
          tooltip: "Eliminar Prestador",
          onClick: (event, rowData) => handleDelete(rowData),
          disabled: esSoporte,
        },
      ]}
      localization={{
        ...localization,
        body: {
          emptyDataSourceMessage: "No hay prestadores que mostrar",
          filterRow: {
            filterTooltip: "Filtrar",
          },
        },
      }}
    />
  );
};
export default ProviderInUserTable;
