import { esES } from "@material-ui/core/locale";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import RoutesContainer from "./components/routes/routesContainer";
import { persistor, store } from "./store";
import { SnackbarUtilsConfigurator } from "./utils/snackbarUtils";

const themeMuiTheme = createTheme(
  {
    palette: {
      primary: {
        light: "#29B6F6",
        main: "#539aff",
        dark: "#70b818",
        backgroundListItem: "#858a93",
        contrastText: "#FFFFFF",
      },
      secondary: {
        light: "#66BB6A",
        main: "#96d43a",
        dark: "#70b818",
        backgroundListItem: "#858a93",
        contrastText: "#FFFFFF",
      },
      success: { main: "#43a047" },
      error: { main: "#d32f2f" },
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "*::-webkit-scrollbar": {
            width: "0.6em !important",
            height: "0.6em !important",
          },
          "*::-webkit-scrollbar-track": {
            backgroundColor: "lightgray !important",
            borderRadius: "7px !important",
            "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00) !important",
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor: "#a3c5f4 !important",
            borderRadius: "7px !important",
            outline: "1px solid slategrey !important",
            border: "none !important",
          },
        },
      },
      MuiTableCell: {
        root: {
          padding: "4px 2px 4px 2px",
        },
      },
    },
  },
  esES,
);

const App = () => (
  <SnackbarProvider maxSnack={10} preventDuplicate>
    <SnackbarUtilsConfigurator />
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MuiThemeProvider theme={themeMuiTheme}>
          <RoutesContainer />
        </MuiThemeProvider>
      </PersistGate>
    </Provider>
  </SnackbarProvider>
);

export default App;
