import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAppointmentAssist,
  getDocuments,
  openAppointmentsGrid,
} from "../../../../actions/accidentDashboard";
import {
  correctConnectivityErrorAction,
  getAllConnectivityErrors,
  getConnectivityErrors,
} from "../../../../actions/connectivityResponse";
import {
  clearFpt,
  getFormProfessionalSpecialties,
  getFormProfessionals,
  postForm,
  setCloseAddForm,
  setFpt,
  setOpenAddForm,
} from "../../../../actions/forms";
import {
  convertDateTimeToNet,
  validateDateRange,
} from "../../../../utils/datetimeUtils";
import CircularLoading from "../../../commons/loadingData/circularLoading";
import Iet from "./iet";

const initialIetModel = {
  nombreTipoFormulario: null,
  fechaAtencion: null,
  nroFormularioFPA: null,
  fechaHoraNuevoControl: null,
  diagnosticoOMS: null,
  diagnostico: "",
  naturalezaLesion: null,
  zonaAfectada: null,
  observaciones: "",
  profesionalApellidoNombre: null,
  profesionalMnMp: "",
  especialidadProfesional: "",
  especialidadInforme: "",
  informacionSensible: false,
  fechaProbableDeAlta: null,
  fechaProrrogaAlta: null,
  representa: "",
};

const appointmentModel = {
  fechaHora: null,
  especialidad: null,
  profesional: null,
  profesionalApellidoNombre: null,
  matricula: "",
  observacion: "",
  traslado: false,
  fromDB: false,
  idTurnoSiniestro: null,
};

const IetContainer = ({
  handleClose,
  isView,
  setLoaded,
  handleOpenConfirmDialog,
  fromErrorDialog,
  fromErroresConectividad,
  selectedDocument,
}) => {
  const dispatch = useDispatch();

  const professionalSpecialties = useSelector(
    (state) => state.forms.professionalSpecialties,
  );
  const accident = useSelector((state) => state.accidentDashboard.accident);
  const saveForm = useSelector((state) => state.forms.saveForm);
  const model = useSelector((state) => state.forms.model);
  const form = useSelector((state) => state.forms.form);
  const fpt = useSelector((state) => state.forms.fpt);
  const diagnosis = useSelector((state) => state.forms.diagnosis);
  const professionals = useSelector((state) => state.forms.professionals);
  const documentation = useSelector(
    (state) => state.accidentDashboard.availableDocumentsToAdd,
  );
  const calendar = useSelector(
    (state) => state.accidentDashboard.appointmentsCalendar,
  );
  const appointmentsPostForm = useSelector(
    (state) => state.accidentDashboard.appointmentsPostForm,
  );
  const time = useSelector(
    (state) => state.connectivityResponse.connectivityErrors.time,
  );
  const [ietModel, setIetModel] = useState(null);
  const [files, setFiles] = useState([]);
  const [openAppointment, setOpenAppointment] = useState(false);
  const [appointment, setAppointment] = useState(appointmentModel);

  const isLoading =
    model.isFetching ||
    saveForm.isFetching ||
    form.isFetching ||
    diagnosis.isFetching ||
    professionals.isFetching ||
    calendar.isFetching ||
    (!isView && !ietModel);

  useEffect(() => {
    if (isView && !form.isFetching) {
      setFiles(form.data.form.adjuntos);
      if (form.data.form.turno && form.data.form.turno.matricula) {
        setAppointment(form.data.form.turno);
      }
    }
  }, [form.isFetching]);

  useEffect(() => {
    if (!model.isFetching && !isView) {
      setIetModel(() => ({
        ...initialIetModel,
        fechaAtencion: new Date(),
        diagnosticoOMS: model.data.fields.diagnosticoOMS
          ? model.data.fields.diagnosticoOMS.options
          : initialIetModel.diagnosticoOMS,
        diagnostico: model.data.fields.diagnostico.options
          ? model.data.fields.diagnostico.options
          : initialIetModel.diagnostico,
        naturalezaLesion: model.data.fields.naturalezaLesion
          ? model.data.fields.naturalezaLesion.options
          : initialIetModel.naturalezaLesion,
        zonaAfectada: model.data.fields.zonaAfectada
          ? model.data.fields.zonaAfectada.options
          : initialIetModel.zonaAfectada,
        profesionalApellidoNombre: model.data.fields.profesionalApellidoNombre
          .options
          ? model.data.fields.profesionalApellidoNombre.options
          : initialIetModel.profesionalApellidoNombre,
        nombreTipoFormulario:
          model.data.fields.tiposFormulario.options.length === 1
            ? model.data.fields.tiposFormulario.options[0].nombre
            : initialIetModel.nombreTipoFormulario,
        especialidadInforme: model.data.fields?.especialidadInforme.options
          ? model.data.fields?.especialidadInforme.options
          : initialIetModel.especialidadInforme,
        informacionSensible: model.data.fields?.informacionSensible.options
          ? model.data.fields?.informacionSensible.options
          : initialIetModel.informacionSensible,
        fechaProbableDeAlta: model.data.fields?.fechaProbableDeAlta?.options
          ? model.data.fields?.fechaProbableDeAlta?.options
          : initialIetModel.fechaProbableDeAlta,
      }));
    }
  }, [model.isFetching]);
  useEffect(() => {
    if (fpt.wasCanceled) {
      setCloseAddForm();
    }
  }, [fpt.wasCanceled]);

  useEffect(() => {
    if (accident.mlCloudCoreArtId) {
      dispatch(getFormProfessionalSpecialties(accident.mlCloudCoreArtId));
    }
  }, [accident.mlCloudCoreArtId]);

  useEffect(() => {
    if (!isLoading && !!setLoaded) {
      setLoaded(true);
    }
  }, [
    model.isFetching,
    saveForm.isFetching,
    form.isFetching,
    diagnosis.isFetching,
    professionals.isFetching,
    calendar.isFetching,
  ]);

  const handleOpenForm = () => {
    setOpenAddForm(t);
  };
  const handleSubmit = (form) => {
    if (!isView) {
      var appointmentsInRange = calendar.data.appointments?.filter(
        (d) =>
          validateDateRange(d.fechaHora, form, null, model) &&
          (d.estado === "Programados" || d.estado === "No informados"),
      );
    }
    if (
      appointmentsInRange &&
      !appointmentsPostForm.wasSent &&
      appointmentsInRange.length != 0
    ) {
      dispatch(openAppointmentsGrid(form));
      if (fpt.wasCanceled) {
        dispatch(clearFpt);
      }
    } else {
      const filesAux = [];
      files.forEach((e) =>
        filesAux.push({
          ...e,
          nombre: e.filename || e.nombre,
          base64: e.value,
        }),
      );
      const model = {
        ...form,
        numeroSiniestroPrestador: accident.idSiniestro,
        adjuntos: filesAux,
        turno: null,
        fechaAtencion: convertDateTimeToNet(form.fechaAtencion),
        fechaHoraNuevoControl: convertDateTimeToNet(form.fechaHoraNuevoControl),
        fechaProbableDeAlta: convertDateTimeToNet(form.fechaProbableDeAlta),
        fechaProrrogaAlta: convertDateTimeToNet(form.fechaProrrogaAlta),
      };
      if (appointment.fechaHora !== null) {
        model.turno = {
          ...appointment,
          fechaHora: convertDateTimeToNet(appointment.fechaHora),
          traslado: appointment.traslado ?? false,
        };
      }
      if (fromErroresConectividad) {
        model.numeroSiniestroPrestador = selectedDocument.siniestro;
      }
      dispatch(postForm("iet", model, accident.mlCloudCoreArtId))
        .then(() => {
          dispatch(getFormProfessionals(accident.mlCloudCoreArtId));
        })
        .then(() => {
          if (fromErrorDialog) {
            dispatch(
              correctConnectivityErrorAction(
                selectedDocument.idRespError,
                accident.mlCloudCoreArtId,
              ),
            ).then((res) => {
              dispatch(
                getDocuments(accident.idSiniestro, accident.mlCloudCoreArtId),
              );
              dispatch(
                getConnectivityErrors(
                  selectedDocument.nroTransaccion,
                  accident.mlCloudCoreArtId,
                ),
              ).then((res) => handleClose(true, false));
            });
          } else {
            handleClose(true, false);
          }

          if (fromErroresConectividad) {
            dispatch(
              correctConnectivityErrorAction(
                selectedDocument.idRespError,
                accident.mlCloudCoreArtId,
              ),
            ).then((res) => {
              dispatch(getAllConnectivityErrors(time));
            });
          }
        })
        .catch(() => handleClose(true, true))
        .finally(() => {
          if (
            appointment.traslado &&
            !appointment.fromDB &&
            documentation.data.find((f) => f.formulario === "Fpt")
              ? true
              : false
          ) {
            const especialidad = professionalSpecialties.data.find(
              (e) => e.id == appointment.especialidad,
            );
            var appointmentAux = {
              ...appointment,
              especialidadNombre: especialidad.nombre,
            };
            dispatch(setFpt({ ...form, ...appointmentAux }));
          }
          dispatch(clearAppointmentAssist());
          setAppointment(appointmentModel);
        });
    }
  };

  useEffect(() => {
    if (appointmentsPostForm.wasSent) {
      handleSubmit(appointmentsPostForm.data);
    }
  }, [appointmentsPostForm.wasSent]);

  const handleAppointmentSubmit = (appointment) => {
    setAppointment(appointment);
    setOpenAppointment(false);
  };

  const handleConfirmClose = () => {
    setOpenAppointment(false);
  };
  return (
    <div style={{ width: "100%" }}>
      {isLoading ? (
        <div style={{ marginTop: 100 }}>
          <CircularLoading />
        </div>
      ) : isView ? (
        <Iet
          handleClose={handleClose}
          files={files}
          setFiles={setFiles}
          handleSubmit={handleSubmit}
          model={form.data.model}
          ietModel={form.data.form}
          roles={form.data.roles}
          selectedDocument={selectedDocument}
          openAppointment={openAppointment}
          handleAppointmentSubmit={handleAppointmentSubmit}
          handleCloseAppointment={() => setOpenAppointment(false)}
          handleOpenAppointment={() => setOpenAppointment(true)}
          appointmentModel={appointment}
          fromErrorDialog={fromErrorDialog}
          fromErroresConectividad={fromErroresConectividad}
          isView={isView}
        />
      ) : (
        <Iet
          handleClose={handleOpenConfirmDialog}
          files={files}
          setFiles={setFiles}
          handleSubmit={handleSubmit}
          model={model.data}
          ietModel={ietModel}
          openAppointment={openAppointment}
          handleAppointmentSubmit={handleAppointmentSubmit}
          handleCloseAppointment={() => handleConfirmClose()}
          handleOpenAppointment={() => setOpenAppointment(true)}
          appointmentModel={appointment}
          openForm={handleOpenForm}
          isView={isView}
        />
      )}
    </div>
  );
};

export default IetContainer;
