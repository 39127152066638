import { Button, makeStyles, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    textAlign: "center",
    padding: 10,
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    color: "white",
    fontWeight: "bold",
    textTransform: "upperCase",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 30,
    paddingBottom: 30,
  },
  actionsContainer: {
    margin: 10,
  },
}));

const FpaConfirmDialog = ({
  handleClose,
  open,
  title,
  text,
  handleConfirmarYFinalizar,
  handleConfirmarYContinuar,
}) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose} disableBackdropClick={true}>
      <DialogTitle className={classes.titleContainer}>
        <Typography className={classes.title}>{title}</Typography>
      </DialogTitle>
      <DialogContent className={classes.contentContainer}>
        <Typography>{text}</Typography>
      </DialogContent>
      <DialogActions className={classes.actionsContainer}>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          type="button"
          onClick={handleConfirmarYFinalizar}>
          Guardar y Finalizar
        </Button>
        <Button
          color="primary"
          variant="contained"
          type="button"
          onClick={handleConfirmarYContinuar}>
          Guardar y Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FpaConfirmDialog;
