import { Dialog, DialogContent, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFormDiagnosis,
  getFormModel,
  getFormProfessionals,
} from "../../../../../actions/forms";
import AmContainer from "../../../forms/am/amContainer";
import FpaContainer from "../../../forms/fpa/fpaContainer";
import FptContainer from "../../../forms/fpt/fptContainer";
import IamiContainer from "../../../forms/iami/iamiContainer";
import IdrContainer from "../../../forms/idr/idrContainer";
import IetContainer from "../../../forms/iet/ietContainer";
import AddFormHeader from "./addFormHeader";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    height: "100vh",
    padding: 0,
  },
}));

const AddFormContainer = ({
  open,
  handleClose,
  form,
  handleOpenConfirmDialog,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const accident = useSelector((state) => state.accidentDashboard.accident);
  const diagnosis = useSelector((state) => state.forms.diagnosis);
  const professionals = useSelector((state) => state.forms.professionals);
  useEffect(() => {
    if (form.formulario && open) {
      dispatch(getFormModel(form.formulario));

      if (
        diagnosis.data.length === 0 &&
        (form.formulario === "Iet" ||
          form.formulario === "Am" ||
          form.formulario === "Iami")
      ) {
        dispatch(getFormDiagnosis(accident.mlCloudCoreArtId));
      }

      if (
        professionals.data.length === 0 &&
        (form.formulario === "Iet" ||
          form.formulario === "Am" ||
          form.formulario === "Iami" ||
          form.formulario === "Fpa" ||
          form.formulario === "Idr")
      ) {
        dispatch(getFormProfessionals(accident.mlCloudCoreArtId));
      }
    }
  }, [form.formulario, open]);

  return (
    <Dialog
      open={open}
      onClose={handleOpenConfirmDialog}
      fullWidth
      maxWidth="md"
      disableBackdropClick={true}>
      <AddFormHeader
        formName={form.nombre}
        handleClose={handleOpenConfirmDialog}
      />
      <DialogContent className={classes.container}>
        {
          {
            Iami: (
              <IamiContainer
                handleOpenConfirmDialog={handleOpenConfirmDialog}
                handleClose={handleClose}
                isView={false}
              />
            ),
            Iet: (
              <IetContainer
                handleOpenConfirmDialog={handleOpenConfirmDialog}
                handleClose={handleClose}
                isView={false}
              />
            ),
            Fpa: (
              <FpaContainer
                handleOpenConfirmCloseDialog={handleOpenConfirmDialog}
                handleClose={handleClose}
                isView={false}
              />
            ),
            Fpt: (
              <FptContainer
                handleOpenConfirmDialog={handleOpenConfirmDialog}
                handleClose={handleClose}
                isView={false}
              />
            ),
            Am: (
              <AmContainer
                handleOpenConfirmDialog={handleOpenConfirmDialog}
                handleClose={handleClose}
                isView={false}
              />
            ),
            Idr: (
              <IdrContainer
                handleOpenConfirmCloseDialog={handleOpenConfirmDialog}
                handleClose={handleClose}
                isView={false}
              />
            ),
          }[form.formulario]
        }
      </DialogContent>
    </Dialog>
  );
};

export default AddFormContainer;
