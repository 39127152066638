import { Typography } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  default as SnackbarUtils,
  default as snackbarUtils,
} from "../../../../utils/snackbarUtils";
import FileTypeDialog from "./fileTypeDialog";
import UploadedFiles from "./uploadedFiles";

const styles = {
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    marginTop: 10,
  },
  dropStyle: {
    flex: 1,
    display: "flex",
    width: "100%",
    padding: 50,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 2,
    borderRadius: 20,
    borderColor: "#a1a1a1",
    borderStyle: "dashed",
    backgroundColor: "#f7f7f7",
    color: "#858585",
    outline: "none",
    transition: "border .24s ease-in-out",
  },
  activeDropStyle: {
    borderColor: "#2196f3",
  },
  acceptDropStyle: {
    borderColor: "#00e676",
  },
  rejectDropStyle: {
    borderColor: "#ff1f4a",
  },
  button: {
    marginTop: 30,
    marginRight: 30,
  },
};

const DropZone = ({
  files,
  setFiles,
  configuration,
  error,
  setError,
  disabled,
  selectedDocument,
  accept,
  dropzoneText,
}) => {
  const [openTypeDialog, setOpenTypeDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});
  const [formatAvailableStrings, setFormatAvailableStrings] = useState([]);
  const [isEditingFile, setIsEditingFile] = useState(false);

  const fileMinSizeInBytes = configuration.fileMinSizeInBytes || 1024;
  // Al utilizar la API en los navegadores Chromium (Chrome, Edge), surgen
  // demasiados errores al utilizar el dropzone. Es mejor desactivarla y que
  // el componente funcione con nuestro código únicamente.
  // Para más detalle ver ticket: MLP-917
  const useBrowserFileSystemAPI = false;

  useEffect(() => {
    const extensiones = Object.values(accept).reduce(
      (acumulador, valorActual) => {
        return acumulador.concat(valorActual);
      },
      [],
    );
    setFormatAvailableStrings(extensiones);
  }, [accept]);

  const handleCloseTypeDialog = () => {
    setOpenTypeDialog(false);
    setSelectedFile({});
    setIsEditingFile(false);
  };

  const handleSubmitTypeDialog = (fileTipificado) => {
    let fileToUpdate = files.find(
      (f) => f.filename === fileTipificado.filename,
    );
    if (fileToUpdate) {
      Object.assign(fileToUpdate, fileTipificado);
    } else {
      files.push(file);
      setError(false);
    }
    handleCloseTypeDialog();
  };

  const validateFile = (file) => {
    const allowedImageFormats = accept["image/*"];
    // Algunas imagenes, como las generadas por WhatsApp, tienen uno (o varios)
    // puntos "." en el nombre. Es por eso que debemos agarrar el último index
    // del punto, el cual corresponde a la extensión.
    const indexExtension = file.name?.lastIndexOf(".");
    const extension = file.name?.slice(indexExtension).toLowerCase();
    const esImagen = file.type?.split("/")[0] == "image";
    if (
      allowedImageFormats &&
      !allowedImageFormats.includes(extension) &&
      esImagen
    ) {
      return {
        code: "file-invalid-type",
      };
    } else {
      return false;
    }
  };

  const onDropAccepted = (file) => {
    let fileArray = files ?? [];
    const reader = new FileReader();

    // En algunos formularios necesitamos que se suba como
    // máximo 1 archivo (como por ejemplo, importar IET).
    // En caso de que el usuario suba un segundo archivo,
    // se debe sobreescribir el archivo anterior.
    if (configuration?.oneFile) {
      const f = file[0];
      reader.readAsDataURL(f);
      reader.onload = function () {
        const index = reader.result.indexOf("base64,") + 7;
        fileArray = fileArray.toSpliced(0, 1, {
          filename: f.name,
          value: reader.result.substring(index),
          base64Header: reader.result.substring(0, index),
          mimeType: f.type,
          bytes: f.size,
        });
        setFiles(fileArray);
        setSelectedFile(f);
        if (configuration.typifyAttachmentFile) {
          setOpenTypeDialog(true);
        }
      };
      reader.onerror = function (error) {
        snackbarUtils.error("Archivo inválido");
      };
    } else {
      file.forEach((f) => {
        reader.readAsDataURL(f);
        reader.onload = function () {
          const index = reader.result.indexOf("base64,") + 7;
          const archivoExistente = fileArray.find((a) => a.filename === f.name);
          if (!!archivoExistente) {
            fileArray = fileArray.toSpliced(0, 1, {
              filename: f.name,
              value: reader.result.substring(index),
              base64Header: reader.result.substring(0, index),
              mimeType: f.type,
              bytes: f.size,
            });
          } else {
            fileArray.push({
              filename: f.name,
              value: reader.result.substring(index),
              base64Header: reader.result.substring(0, index),
              mimeType: f.type,
              bytes: f.size,
            });
          }
          setFiles(fileArray);
          setSelectedFile(f);
          if (configuration.typifyAttachmentFile) {
            setOpenTypeDialog(true);
          }
        };
        reader.onerror = function (error) {
          snackbarUtils.error("Archivo inválido");
        };
      });
    }
  };

  const onDropRejected = (error) => {
    error[0].errors.forEach((error) => {
      switch (error.code) {
        case "file-too-large":
          SnackbarUtils.error(
            `El archivo debe pesar menos de ${configuration.maxFileSizeBytes} bytes`,
          );
          break;
        case "file-invalid-type":
          SnackbarUtils.error(
            `El archivo debe ser ${formatAvailableStrings.toString()}`,
          );
          break;
        case "file-too-small":
          SnackbarUtils.error(
            `El archivo debe pesar mas de ${fileMinSizeInBytes} bytes`,
          );
          break;
        default:
          SnackbarUtils.error("Archivo no permitido");
          break;
      }
    });
  };

  const handleEditFile = (file) => {
    setIsEditingFile(true);
    setSelectedFile(file);
    setOpenTypeDialog(true);
  };

  const handleDeleteFile = (file) => {
    setFiles(files.filter((f) => f.filename !== file.filename));
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDropAccepted,
    onDropRejected,
    useFsAccessApi: useBrowserFileSystemAPI,
    accept: accept,
    validator: validateFile,
    multiple: configuration.oneFile ? false : true,
    maxSize: configuration.maxFileSizeBytes,
    minSize: fileMinSizeInBytes,
    disabled: disabled,
  });

  const style = useMemo(
    () => ({
      ...styles.dropStyle,
      ...(isDragActive ? styles.activeDropStyle : {}),
      ...(isDragAccept ? styles.acceptDropStyle : {}),
      ...(isDragReject ? styles.rejectDropStyle : {}),
      cursor: disabled ? "default" : "pointer",
    }),
    [isDragActive, isDragReject, isDragAccept],
  );

  return (
    <div style={styles.container}>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <Typography variant="subtitle1" style={{ textAlign: "center" }}>
          {dropzoneText ??
            "Arrastre o haga click en el recuadro para adjuntar archivos"}
        </Typography>
      </div>
      <UploadedFiles
        files={files}
        handleEdit={handleEditFile}
        handleDeleteFile={handleDeleteFile}
        configuration={configuration}
        selectedDocument={selectedDocument}
        disabled={disabled}
      />
      <FileTypeDialog
        file={selectedFile}
        open={openTypeDialog}
        handleClose={handleCloseTypeDialog}
        handleSubmit={handleSubmitTypeDialog}
        configuration={configuration}
        isEditingFile={isEditingFile}
        deleteLastFile={() =>
          setFiles(files.filter((e) => e.filename != selectedFile.name))
        }
      />
      {error && (
        <Typography color="error">Debe adjuntar al menos un archivo</Typography>
      )}
    </div>
  );
};

export default DropZone;
