import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { getFiles, updateFile } from "../../../actions/files";
import snackbarUtils from "../../../utils/snackbarUtils";
import FormikActions from "../../commons/formik/formikActions";
import FormikTextField from "../../commons/formik/formikTextField";
import DropZone from "../forms/dropZone/dropZone";

const validationSchema = yup.object({
  descripcion: yup.string().required("Campo obligatorio"),
});

const initialValues = {
  descripcion: "",
};

const EditFileDialog = ({ open, file, handleClose }) => {
  const dispatch = useDispatch();

  const [dropzoneFile, setDropzoneFile] = useState([]);
  const [attachmentError, setAttachmentError] = useState(false);

  const handleSubmit = (values) => {
    const model = {
      ...values,
      base64: dropzoneFile.length > 0 ? dropzoneFile[0].value : file.base64,
    };
    dispatch(updateFile(model)).then((res) => {
      dispatch(getFiles()).then(() => {
        snackbarUtils.success("Archivo modificado exitosamente");
        handleClose();
      });
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Editar Archivo</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={file ? file : initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}>
          <Form>
            <FormikTextField
              name="descripcion"
              label="Descripción"
              type="text"
              fullWidth
            />
            <div style={{ marginTop: 20 }}>
              <DropZone
                files={dropzoneFile}
                setFiles={setDropzoneFile}
                configuration={{
                  maxFileSizeBytes: 500000,
                  fileMinSizeInBytes: 300,
                  oneFile: true,
                  typifyAttachmentFile: false,
                }}
                error={attachmentError}
                setError={setAttachmentError}
                accept={{ "application/csv": [".csv"] }}
              />
            </div>
            <DialogActions>
              <FormikActions submitText="Guardar" handleCancel={handleClose} />
            </DialogActions>
          </Form>
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default EditFileDialog;
