import { Collapse, List, ListItem, ListItemIcon } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core/styles";
import {
  Add,
  AppsOutlined,
  Assignment,
  AssignmentLateOutlined,
  AssignmentReturnedOutlined,
  AssignmentTurnedInOutlined,
  Attachment,
  BarChart,
  Email,
  ErrorOutlineRounded,
  Event,
  ExpandLess,
  ExpandMore,
  FileCopy,
  Home,
  LocalHospital,
  PeopleOutline,
  QueryBuilder,
  Search,
  SettingsOutlined,
} from "@material-ui/icons";
import DescriptionIcon from "@material-ui/icons/Description";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import DrawerItemTooltip from "./drawerItemTooltip";
import ListItemComponent from "./listItem";
import ListSubItemComponent from "./listSubItem";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "top",
    width: "100%",
    maxWidth: 360,
    backgroundColor: "#ffffff",
    padding: "5px",
    fontSize: 13.5,
  },
  nested: {
    color: theme.palette.primary.backgroundListItem,
  },
  icon: {
    [theme.breakpoints.up("sm")]: {
      minWidth: 20,
    },
  },
  nodeParentText: {
    color: theme.palette.primary.backgroundListItem,
    fontFamily: "Roboto, sans-serif",
    margin: "0 0 0 0",
    fontWeight: "bold",
    flex: 1,
  },
  nodeParent: {
    borderRadius: "7px",
    width: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "8px",
  },
  expandArrow: {
    color: theme.palette.primary.backgroundListItem,
  },
  rightIconContainer: {
    display: "flex",
    alignItems: "center",
  },
  badge: {
    marginRight: 10,
  },
}));

export default function NestedList() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const roles = useSelector((state) => state.auth.roles);
  const drawerOpen = useSelector((state) => state.userPreferences.drawer);
  const answeredAuthorizationsCount = useSelector(
    (state) => state.answeredAuthorizations.notification,
  );
  const erroresConectividadNoProcesados = useSelector(
    (state) => state.connectivityResponse.allConnectivityErrorsNotProcessed,
  );
  const [open, setOpen] = React.useState(true);
  const [openAccidents, setOpenAccidents] = React.useState(true);
  const [openMenu, setOpenMenu] = React.useState(true);
  const [openAppointment, setOpenAppointment] = React.useState(true);
  const [openErroresConectividad, setOpenErroresConectividad] =
    React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickAccidents = () => {
    setOpenAccidents(!openAccidents);
  };

  const handleClickMenu = () => {
    setOpenMenu(!openMenu);
  };

  const handleClickAppointment = () => {
    setOpenAppointment(!openAppointment);
  };
  const handleClickErroresConectividad = () => {
    setOpenErroresConectividad(!openErroresConectividad);
  };
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}>
      {roles && roles.ADMIN && roles.tipo === "Administrador" && (
        <div>
          <DrawerItemTooltip
            drawerOpen={drawerOpen}
            tooltipText="Configuración">
            <ListItem
              button
              onClick={handleClickMenu}
              className={classes.nodeParent}
              id="configuration">
              <ListItemIcon>
                <SettingsOutlined
                  style={{ margin: "0 0 0 0" }}
                  fontSize="small"
                />
              </ListItemIcon>
              <p className={classes.nodeParentText}>Configuración</p>
              {openMenu ? (
                <ExpandLess className={classes.expandArrow} />
              ) : (
                <ExpandMore className={classes.expandArrow} />
              )}
            </ListItem>
          </DrawerItemTooltip>
          <Collapse in={openMenu} timeout="auto" unmountOnExit>
            <ListSubItemComponent
              path="/configuration/users"
              selected={location.pathname === "/configuration/users"}
              title="Usuarios"
              id="configuration_users"
              icon={<PeopleOutline className={classes.icon} />}
              open={drawerOpen}
            />
            <ListSubItemComponent
              path="/configuration/arts"
              selected={location.pathname === "/configuration/arts"}
              title="ARTs"
              id="configuration_arts"
              icon={<AppsOutlined className={classes.icon} />}
              open={drawerOpen}
            />
            <ListSubItemComponent
              path="/configuration/providers"
              selected={location.pathname === "/configuration/providers"}
              title="Prestadores"
              id="configuration_providers"
              icon={<LocalHospital className={classes.icon} />}
              open={drawerOpen}
            />
            <ListSubItemComponent
              path="/configuration/files"
              selected={location.pathname === "/configuration/files"}
              title="Archivos"
              id="configuration_files"
              icon={<DescriptionIcon className={classes.icon} />}
              open={drawerOpen}
            />
          </Collapse>
        </div>
      )}
      {roles && roles.ADMIN && roles.tipo === "Soporte" && (
        <div>
          <DrawerItemTooltip
            drawerOpen={drawerOpen}
            tooltipText="Configuración">
            <ListItem
              button
              onClick={handleClickMenu}
              className={classes.nodeParent}
              id="configuration">
              <ListItemIcon>
                <SettingsOutlined />
              </ListItemIcon>
              <p className={classes.nodeParentText}>Configuración</p>
              {openMenu ? (
                <ExpandLess className={classes.expandArrow} />
              ) : (
                <ExpandMore className={classes.expandArrow} />
              )}
            </ListItem>
          </DrawerItemTooltip>
          <Collapse in={openMenu} timeout="auto" unmountOnExit>
            <ListSubItemComponent
              path="/configuration/users"
              selected={location.pathname === "/configuration/users"}
              title="Usuarios"
              id="configuration_users"
              icon={<PeopleOutline className={classes.icon} />}
              open={drawerOpen}
            />
          </Collapse>
        </div>
      )}
      {roles && roles.ART && (
        <div>
          <ListItemComponent
            open={drawerOpen}
            path="/art/prestadores"
            title="Prestadores"
            id="prestadores"
            icon={<LocalHospital className={classes.icon} />}
          />
          <DrawerItemTooltip drawerOpen={drawerOpen} tooltipText="Siniestros">
            <ListItem
              button
              onClick={handleClick}
              className={classes.nodeParent}
              id="accidents">
              <ListItemIcon>
                <Assignment />
              </ListItemIcon>
              <p className={classes.nodeParentText}>Siniestros</p>
              {open ? (
                <ExpandLess className={classes.expandArrow} />
              ) : (
                <ExpandMore className={classes.expandArrow} />
              )}
            </ListItem>
          </DrawerItemTooltip>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <ListSubItemComponent
              path="/art/accidents/search"
              selected={location.pathname === "/art/accidents/search"}
              title="Buscar"
              id="accidents_search"
              icon={<Search className={classes.icon} />}
              open={drawerOpen}
            />
          </Collapse>
        </div>
      )}
      {roles && roles.PRESTADOR && (
        <div>
          <ListItemComponent
            open={drawerOpen}
            path="/home"
            title="Inicio"
            id="home"
            icon={<Home className={classes.icon} />}
          />
          <ListItemComponent
            open={drawerOpen}
            path="/myWris"
            title="Mis ARTs"
            id="myWris"
            icon={<AppsOutlined className={classes.icon} />}
          />
          <DrawerItemTooltip drawerOpen={drawerOpen} tooltipText="Siniestros">
            <ListItem
              button
              onClick={handleClick}
              className={classes.nodeParent}
              id="accidents">
              <ListItemIcon>
                <Assignment />
              </ListItemIcon>
              <p className={classes.nodeParentText}>Siniestros</p>
              {open ? (
                <ExpandLess className={classes.expandArrow} />
              ) : (
                <ExpandMore className={classes.expandArrow} />
              )}
            </ListItem>
          </DrawerItemTooltip>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <ListSubItemComponent
              path="/accidents/current"
              selected={location.pathname === "/accidents/current"}
              title="En curso"
              id="accidents_current"
              icon={<QueryBuilder className={classes.icon} />}
              open={drawerOpen}
            />
            <ListSubItemComponent
              path="/accidents/search"
              selected={location.pathname === "/accidents/search"}
              title="Buscar"
              id="accidents_search"
              icon={<Search className={classes.icon} />}
              open={drawerOpen}
            />
            <ListSubItemComponent
              path="/accidents/new"
              selected={location.pathname === "/accidents/new"}
              title="Nuevo"
              id="accidents_new"
              icon={<Add className={classes.icon} />}
              open={drawerOpen}
            />
            <ListSubItemComponent
              path="/accidents/importIet"
              selected={location.pathname === "/accidents/importIet"}
              title="Importar IET"
              id="import_iet"
              icon={<Attachment className={classes.icon} />}
              open={drawerOpen}
            />
          </Collapse>
          <DrawerItemTooltip
            drawerOpen={drawerOpen}
            tooltipText="Autorizaciones">
            <ListItem
              button
              onClick={handleClickAccidents}
              className={classes.nodeParent}
              id="authorizations">
              <ListItemIcon>
                <FileCopy />
              </ListItemIcon>
              <p className={classes.nodeParentText}>Autorizaciones</p>
              {openAccidents ? (
                <ExpandLess className={classes.expandArrow} />
              ) : answeredAuthorizationsCount.count > 0 ? (
                <Badge
                  badgeContent={answeredAuthorizationsCount.count}
                  color="primary"
                  className={classes.badge}
                />
              ) : (
                <ExpandMore className={classes.expandArrow} />
              )}
            </ListItem>
          </DrawerItemTooltip>
          <Collapse in={openAccidents} timeout="auto" unmountOnExit>
            <ListSubItemComponent
              path="/authorizations/pending"
              selected={location.pathname === "/authorizations/pending"}
              title="Pendientes"
              id="authorizations_pending"
              icon={<ErrorOutlineRounded className={classes.icon} />}
              open={drawerOpen}
            />
            <ListSubItemComponent
              path="/authorizations/answered"
              selected={location.pathname === "/authorizations/answered"}
              title="Respondidas"
              id="authorizations_answered"
              icon={<AssignmentTurnedInOutlined className={classes.icon} />}
              open={drawerOpen}
              notification={answeredAuthorizationsCount.count}
            />
            <ListSubItemComponent
              path="/authorizations/search"
              selected={location.pathname === "/authorizations/search"}
              title="Buscar"
              id="authorizations_search"
              icon={<Search className={classes.icon} />}
              open={drawerOpen}
            />
          </Collapse>
          <DrawerItemTooltip drawerOpen={drawerOpen} tooltipText="Turnos">
            <ListItem
              button
              onClick={handleClickAppointment}
              className={classes.nodeParent}
              id="appointments">
              <ListItemIcon>
                <Event />
              </ListItemIcon>
              <p className={classes.nodeParentText}>Turnos</p>
              {openAppointment ? (
                <ExpandLess className={classes.expandArrow} />
              ) : (
                <ExpandMore className={classes.expandArrow} />
              )}
            </ListItem>
          </DrawerItemTooltip>

          <Collapse in={openAppointment} timeout="auto" unmountOnExit>
            <ListSubItemComponent
              path="/appointments/pending"
              selected={location.pathname === "/appointments/pending"}
              title="Asistencias"
              title2="pendientes"
              id="appointments_pending"
              icon={<ErrorOutlineRounded className={classes.icon} />}
              open={drawerOpen}
            />
            <ListSubItemComponent
              path="/appointments/search"
              selected={location.pathname === "/appointments/search"}
              title="Buscar"
              id="appointments_search"
              icon={<Search className={classes.icon} />}
              open={drawerOpen}
            />
          </Collapse>
          <DrawerItemTooltip
            drawerOpen={drawerOpen}
            tooltipText="Resp. de Conectividad">
            <ListItem
              button
              onClick={handleClickErroresConectividad}
              className={classes.nodeParent}
              id="respuestasConectividad">
              <ListItemIcon>
                <AssignmentReturnedOutlined />
              </ListItemIcon>
              <p className={classes.nodeParentText}>Resp. de Conectividad</p>
              {openAccidents ? (
                <ExpandLess className={classes.expandArrow} />
              ) : erroresConectividadNoProcesados > 0 ? (
                <Badge
                  badgeContent={erroresConectividadNoProcesados}
                  color="primary"
                  className={classes.badge}
                />
              ) : (
                <ExpandMore className={classes.expandArrow} />
              )}
            </ListItem>
          </DrawerItemTooltip>
          <Collapse in={openErroresConectividad} timeout="auto" unmountOnExit>
            <ListSubItemComponent
              path="/respuestasConectividad/errors"
              selected={location.pathname === "/respuestasConectividad/errors"}
              title="Errores"
              id="errores"
              icon={<AssignmentLateOutlined className={classes.icon} />}
              open={drawerOpen}
              notification={erroresConectividadNoProcesados.data}
            />
            <ListSubItemComponent
              path="/respuestasConectividad/search"
              selected={location.pathname === "/respuestasConectividad/search"}
              title="Buscar"
              id="search"
              icon={<Search className={classes.icon} />}
              open={drawerOpen}
            />
          </Collapse>
          <ListItemComponent
            open={drawerOpen}
            path="/statistic"
            title="Estadísticas"
            id="statistic"
            icon={<BarChart className={classes.icon} />}
          />
          <ListItemComponent
            open={drawerOpen}
            path="/emails"
            title="Correos"
            id="emails"
            icon={<Email className={classes.icon} />}
          />
        </div>
      )}
    </List>
  );
}
