import { makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAppointmentsCalendar,
  getDocuments,
} from "../../../../../../actions/accidentDashboard";
import { clearIdTurnos } from "../../../../../../actions/appointments";
import {
  clearIdTurnosForms,
  getForm,
  getFormDiagnosis,
  getFormPrestaciones,
  getFormProfessionals,
  getFormSpecialtiesDiagnosis,
  setChangeDocument,
  setOpenAccidentDocumentsDetail,
} from "../../../../../../actions/forms";
import snackbarUtils from "../../../../../../utils/snackbarUtils";
import AmContainer from "../../../../forms/am/amContainer";
import FpaContainer from "../../../../forms/fpa/fpaContainer";
import FptContainer from "../../../../forms/fpt/fptContainer";
import IamiContainer from "../../../../forms/iami/iamiContainer";
import IdrContainer from "../../../../forms/idr/idrContainer";
import IetContainer from "../../../../forms/iet/ietContainer";
import AccidentDocumentsDetailCards from "./accidentDocumentsDetailCards";
import AccidentDocumentsDetailHeader from "./accidentDocumentsDetailHeader";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    height: "100vh",
    padding: 0,
  },
  documentsContainer: {
    display: "flex",
    flex: 1,
    backgroundColor: "#f5f5f5",
  },
  documentContainer: {
    display: "flex",
    justifyContent: "center",
    flex: 3,
  },
}));

const AccidentDocumentsDetail = ({
  open,
  data,
  selectedDocument,
  setSelectedDocument,
  fromErroresConectividad,
  fromErrorDialog,
  handleCloseErroresConectividad,
  setFromErrorDialog,
  setFromErroresConectividad,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const accident = useSelector((state) => state.accidentDashboard.accident);
  const professionals = useSelector((state) => state.forms.professionals);
  const diagnosis = useSelector((state) => state.forms.diagnosis);
  const prestacionesState = useSelector((state) => state.forms.prestaciones);
  const specialties = useSelector((state) => state.forms.specialtiesDiagnosis);

  const changeDocument = useSelector((state) => state.forms.changeDocument);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (selectedDocument && open) {
      dispatch(
        getForm(
          selectedDocument.comportamiento,
          accident.mlCloudCoreArtId,
          accident.idSiniestro,
          selectedDocument.nroFormulario,
        ),
      );
    }
    dispatch(setChangeDocument(true));
  }, [selectedDocument, open]);
  const handleClose = (formSubmited, error) => {
    if (setFromErrorDialog) {
      setFromErrorDialog(false);
    }
    if (setFromErroresConectividad) {
      setFromErroresConectividad(false);
    }
    dispatch(clearIdTurnosForms());
    dispatch(clearIdTurnos());
    dispatch(setOpenAccidentDocumentsDetail(false));
    if (formSubmited === true) {
      if (!fromErroresConectividad) {
        dispatch(getDocuments(accident.idSiniestro, accident.mlCloudCoreArtId));
        dispatch(
          getAppointmentsCalendar(
            accident.idSiniestro,
            accident.mlCloudCoreArtId,
          ),
        );
      }
      if (!error) {
        snackbarUtils.success("Formulario modificado correctamente");
      }
    }
  };
  useEffect(() => {
    if (open && professionals.data.length === 0) {
      dispatch(getFormProfessionals(accident.mlCloudCoreArtId));
    }

    if (open && diagnosis.data.length === 0) {
      dispatch(getFormDiagnosis(accident.mlCloudCoreArtId));
    }

    if (
      open &&
      selectedDocument.comportamiento === "Fpa" &&
      !prestacionesState.data.prestaciones
    ) {
      dispatch(getFormPrestaciones(accident.mlCloudCoreArtId));
    }

    if (
      open &&
      selectedDocument.comportamiento === "Am" &&
      specialties.data.length === 0
    ) {
      dispatch(getFormSpecialtiesDiagnosis(accident.mlCloudCoreArtId));
    }

  }, [open, selectedDocument]);

  const changeSelectedDocument = (document) => {
    if (selectedDocument.nroFormulario !== document.nroFormulario) {
      dispatch(setChangeDocument(false));
      setLoaded(false);
      setSelectedDocument(document);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      disableBackdropClick={true}>
      <AccidentDocumentsDetailHeader
        handleClose={handleClose}
        selectedDocument={selectedDocument}
      />
      <DialogContent className={classes.container}>
        {!fromErroresConectividad && !fromErrorDialog && (
          <div className={classes.documentsContainer}>
            <AccidentDocumentsDetailCards
              data={data}
              selectedDocument={selectedDocument}
              changeSelectedDocument={changeSelectedDocument}
              disableChange={!loaded}
            />
          </div>
        )}
        <div className={classes.documentContainer}>
          {changeDocument &&
            {
              Iami: (
                <IamiContainer
                  handleClose={handleClose}
                  isView={true}
                  setLoaded={setLoaded}
                  selectedDocument={selectedDocument}
                  fromErroresConectividad={fromErroresConectividad}
                  fromErrorDialog={fromErrorDialog}
                  handleCloseErroresConectividad={
                    handleCloseErroresConectividad
                  }
                />
              ),
              Iet: (
                <IetContainer
                  handleClose={handleClose}
                  isView={true}
                  setLoaded={setLoaded}
                  selectedDocument={selectedDocument}
                  fromErroresConectividad={fromErroresConectividad}
                  fromErrorDialog={fromErrorDialog}
                  handleCloseErroresConectividad={
                    handleCloseErroresConectividad
                  }
                />
              ),
              Fpa: (
                <FpaContainer
                  handleClose={handleClose}
                  isView={true}
                  setLoaded={setLoaded}
                  selectedDocument={selectedDocument}
                  fromErroresConectividad={fromErroresConectividad}
                  fromErrorDialog={fromErrorDialog}
                  handleCloseErroresConectividad={
                    handleCloseErroresConectividad
                  }
                />
              ),
              Fpt: (
                <FptContainer
                  handleClose={handleClose}
                  isView={true}
                  setLoaded={setLoaded}
                  selectedDocument={selectedDocument}
                  fromErroresConectividad={fromErroresConectividad}
                  fromErrorDialog={fromErrorDialog}
                  handleCloseErroresConectividad={
                    handleCloseErroresConectividad
                  }
                />
              ),
              Am: (
                <AmContainer
                  handleClose={handleClose}
                  isView={true}
                  setLoaded={setLoaded}
                  selectedDocument={selectedDocument}
                  fromErroresConectividad={fromErroresConectividad}
                  fromErrorDialog={fromErrorDialog}
                  handleCloseErroresConectividad={
                    handleCloseErroresConectividad
                  }
                />
              ),
              Idr: (
                <IdrContainer
                  handleClose={handleClose}
                  isView={true}
                  setLoaded={setLoaded}
                  selectedDocument={selectedDocument}
                  fromErroresConectividad={fromErroresConectividad}
                  fromErrorDialog={fromErrorDialog}
                  handleCloseErroresConectividad={
                    handleCloseErroresConectividad
                  }
                />
              ),
            }[selectedDocument.comportamiento]}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AccidentDocumentsDetail;
