import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPrePrintedForms,
  getPrePrintedHTML,
} from "../../../../../actions/accidentDashboard";
import { parseHTMLToReact } from "../../../../../utils/util";
import PrintAndDownloadDialog from "../../../../commons/dialog/PrintAndDownloadDialog";
import CardTitle from "../cardTitle";
import AccidentFormsCards from "./accidentPrePrintedFormsCards";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
}));

const AccidentForms = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const forms = useSelector((state) => state.accidentDashboard.prePrintedForms);
  const accident = useSelector((state) => state.accidentDashboard.accident);
  const html = useSelector((state) => state.accidentDashboard.prePrintedHTML);

  const [openModal, setOpenModal] = useState(false);
  const [selectedForm, setSelectedForm] = useState({});

  useEffect(() => {
    dispatch(getPrePrintedForms(accident.mlCloudCoreArtId, accident.idSiniestro));
  }, []);

  const handleCloseModal = () => {
    setSelectedForm({});
    setOpenModal(false);
  };

  const generatePrePrintedHTML = (form) => {
    setSelectedForm(form);
    setOpenModal(true);
    dispatch(
      getPrePrintedHTML({
        accidentId: accident.idSiniestro,
        WRIId: accident.mlCloudCoreArtId,
        templateId: form.idPlantillaPreImpresion,
      }),
    );
  };

  return (
    <div className={classes.container}>
      <CardTitle title="FORMULARIOS" color="primary" />
      <AccidentFormsCards
        data={forms.data}
        loading={forms.isFetching}
        generatePrePrintedHTML={generatePrePrintedHTML}
      />
      <PrintAndDownloadDialog
        maxWidth="lg"
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        childComponent={
          <React.Fragment>{parseHTMLToReact(html.data)}</React.Fragment>
        }
        loading={html.isFetching || !openModal}
        printStyle={{ margin: "20px" }}
        title={selectedForm.nombre}
        showDownloadButton={false}
        reactToPrintProps={{ suppressErrors: true }}
      />
    </div>
  );
};

export default AccidentForms;
