import { Paper, Tooltip, Typography } from "@material-ui/core";
import { Close, Edit } from "@material-ui/icons";
import moment from "moment";
import React from "react";
const ErroresConectividadCardDetail = ({
  error,
  handleOpenDetail,
  selectedDocument,
  desestimarErrorConectividad,
}) => {
  return (
    <Paper style={{ marginBottom: 10, padding: 10, display: "flex" }}>
      <div>
        <Typography>
          {error.fechaHoraProcesada
            ? moment(error.fechaHoraProcesada).format("DD/MM/YYYY")
            : ""}
        </Typography>
        <Typography>
          <strong>Observación:</strong> {error.observaciones}
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}>
        <Tooltip arrow title="Editar">
          <Edit
            style={{ margin: 5, cursor: "pointer" }}
            onClick={() => handleOpenDetail(selectedDocument, error)}
          />
        </Tooltip>
        <Tooltip arrow title="Desestimar">
          <Close
            style={{ margin: 5, cursor: "pointer" }}
            onClick={() => desestimarErrorConectividad(error)}
          />
        </Tooltip>
      </div>
    </Paper>
  );
};

export default ErroresConectividadCardDetail;
