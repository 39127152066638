import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import {
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    fontFamily: "Roboto, sans-serif",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "default",
  },
}));

export default function PrivacyPoliciesDialog({ open, onClose }) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <h2 style={{ margin: -2 }}>
          Políticas de Privacidad y Confidencialidad
        </h2>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description" align="justify">
          Como parte normal de nuestra actividad recogemos y, en algunos casos,
          revelamos información sobre nuestros usuarios y visitantes de nuestro
          sitio web ML.Point. Estas Políticas de Privacidad (las "políticas de
          privacidad") describen la información que ACCESS INFORMATICA S.R.L.
          recoge sobre sus usuarios y visitantes y lo que puede hacerse con
          dicha información. Esta política es muy detallada porque es importante
          que todos los usuarios y visitantes de nuestro sitio conozcan las
          prácticas de ACCESS INFORMATICA S.R.L. de la forma más precisa
          posible. Al registrarse y/o navegar el sitio web ML.Point, el usuario
          y/o el visitante presta su consentimiento para que se utilice su
          información de acuerdo a las políticas que se describen a
          continuación.
          <br />
          <br />
          Este documento es parte integrante de los Términos y Condiciones de
          ACCESS INFORMATICA S.R.L.. Mediante la aceptación de los Términos y
          Condiciones, el usuario acepta las políticas aquí contenidas.
          <br />
          <br />
          <b>
            Derechos de acceso, cancelación y rectificación de la información
          </b>
          <br />
          <br />
          Los usuarios tienen reconocidos y podrán ejercitar los derechos de
          acceder, rectificar y suprimir su Información Personal, incluyendo su
          dirección de e-mail, así como a oponerse al tratamiento de la misma y
          a ser informado de las cesiones llevadas a cabo, todo ello de
          conformidad a lo dispuesto en la normativa aplicable.
          <br />
          <br />
          Conforme se establece en el artículo 14, inciso 3 de la Ley Nº 25.326,
          el titular de los datos personales, previa acreditación de su
          identidad, tiene la facultad de ejercer el derecho de acceso a los
          mismos, dentro de los 10 (diez) días corridos desde la acreditación de
          su identidad, en forma gratuita a intervalos no inferiores a seis
          meses, salvo que se acredite un interés legítimo al efecto. Para la
          rectificación y supresión de los datos personales, el titular de los
          mismos podrá ejercer tales derechos dentro de los 5 (cinco) días
          hábiles de acreditada su identidad.
          <br />
          <br />
          Los usuarios garantizan y responden, en cualquier caso, de la
          veracidad, exactitud, vigencia y autenticidad de la Información
          Personal facilitada, y se comprometen a mantenerla debidamente
          actualizada.
          <br />
          <br />
          Una vez registrado en ACCESS INFORMATICA S.R.L., el usuario podrá
          revisar y cambiar la información que nos ha enviado durante el proceso
          de registración incluyendo:
          <br />
          <br />
          • El Apodo y dirección de correo electrónico. Sin perjuicio de los
          cambios que realice, ACCESS INFORMATICA S.R.L. conservará la
          Información Personal anterior por motivos de seguridad y control del
          fraude.
          <br />
          <br />
          • La asociación con un Prestador.
          <br />
          <br />
          • La clave.
          <br />
          <br />
          En cualquier caso, la Información Personal de un usuario no será
          inmediatamente retirada de nuestros archivos por motivos legales y
          técnicos, incluyendo sistemas de soportes de seguridad. Por tanto, no
          se debe esperar que toda la Información Personal sea definitivamente
          borrada de nuestras bases de datos.
          <br />
          <br />
          Para hacer cualquier modificación en la información se debe enviar un
          mail a xxxxxxx
          <br />
          <br />
          ACCESS INFORMATICA S.R.L. podrá modificar en cualquier momento los
          términos y condiciones de estas Políticas de Privacidad y
          confidencialidad y/o las prácticas de envío de e-mails. Si decidimos
          introducir algún cambio material a nuestras Políticas de Privacidad,
          te notificaremos publicando una versión actualizada de las Políticas
          en esta sección o mediante el envío de un e-mail o informándolo en la
          página principal u otras secciones del sitio para mantenerte
          actualizado de los cambios realizados.
          <br />
          <br />
          Si no aceptas esos términos, en ese caso quedará disuelto el vínculo y
          tu Información Personal no será usada de otra forma que la que fue
          informada al momento de recabarse.
          <br />
          <br />
          Para mayor información sobre la confidencialidad de tu Información
          Personal, contáctanos por correo electrónico o postal.
          <br />
          <br />
          Se informa que la Agencia de Acceso a la Información Pública, Órgano
          de Control de la Ley Nº 25.326, tiene la atribución de atender las
          denuncias y reclamos que se interpongan con relación al incumplimiento
          de las normas sobre protección de datos personales.
          <br />
          <br />
          Para el ejercicio de tus derechos, dirigite el final de este
          documento, a la sección "Medios para ejercer los derechos de acceso,
          rectificación y supresión de los datos personales".
          <br />
          <br />
          <b>La información que recabamos</b>
          <br />
          <br />
          El Usuario que se registre en ML.Point, consiente expresamente que
          ACCESS INFORMATICA S.R.L.:
          <br />
          <br />
          • Tenga acceso, en cualquier momento, a la totalidad de la información
          contenida en su Cuenta Personal.
          <br />
          <br />
          • Envíe al correo electrónico del Usuario vinculado a la Cuenta
          Personal, información o mensajes.
          <br />
          <br />
          ACCESS INFORMATICA S.R.L. recoge y almacena automáticamente cierta
          información sobre la actividad de los usuarios y visitantes dentro de
          su sitio web. Tal información puede incluir la URL de la que provienen
          (estén o no en nuestro sitio web), a qué URL acceden seguidamente, qué
          navegador están usando, y sus direcciones IP. También las páginas
          visitadas, las búsquedas realizadas, entre otra información podrá ser
          almacenada y retenida.
          <br />
          <br />
          <b>Uso que hacemos de la información</b>
          <br />
          <br />
          Para suministrar un mejor servicio, ACCESS INFORMATICA S.R.L. requiere
          cierta información de carácter personal, incluyendo dirección de
          e-mail. La recolección de información nos permite ofrecer a los
          usuarios servicios y funcionalidades que se adecuen mejor a sus
          necesidades y personalizar nuestros servicios para hacer que sus
          experiencias con ML.Point sean lo más cómodas posible. La Información
          Personal que recabamos tiene las siguientes finalidades:
          <br />
          <br />
          • Ayudar al usuario a tener una mejor experiencia de uso del sitio
          web.
          <br />
          <br />
          • Desarrollar estudios internos.
          <br />
          <br />• Mejorar nuestras iniciativas comerciales y promocionales y
          analizar las páginas visitadas las búsquedas realizadas por los
          usuarios, para mejorar nuestro servicio.
          <br />
          <br />• Enviar información o mensajes por e-mail sobre nuevos
          servicios. Si el usuario lo prefiere, puede solicitar que lo excluyan
          de las listas para el envío de información promocional o publicitaria.
          <br />
          <br />
          Compartir la información Personal (incluyendo dirección de e-mail) con
          los proveedores de servicios o con las empresas con quienes ACCESS
          INFORMATICA S.R.L. tenga una relación de colaboración o alianza, que
          contribuyan a mejorar o facilitar las operaciones a través de ML.Point
          <br />
          <br />
          <b>Confidencialidad de la información</b>
          <br />
          <br />
          Puede suceder que en virtud de órdenes judiciales, o de regulaciones
          legales, nos veamos compelidos a revelar información a las autoridades
          o terceras partes bajo ciertas circunstancias, o bien en casos que
          terceras partes puedan interceptar o acceder a cierta información o
          transmisiones de datos en cuyo caso ACCESS INFORMATICA S.R.L. no
          responderá por la información que sea revelada.
          <br />
          <br />
          <b>Clave Personal</b>
          <br />
          <br />
          Para acceder al sitio se requiere ser un usuario debidamente
          registrado y disponer de una clave personal. Esta clave deben
          mantenerla bajo absoluta confidencialidad y, en ningún caso, deberán
          revelarla o compartirla con otras personas. El usuario será
          responsable de todos los actos que tengan lugar mediante el uso de su
          Clave. Si por cualquier razón un usuario creyera que alguien puede
          conocer su clave, deberá modificarla ingresando desde el menú de
          navegación.
          <br />
          <br />
          <b>Menores de Edad</b>
          <br />
          <br />
          Nuestros servicios sólo están disponibles para aquellas personas que
          tengan capacidad legal para contratar. Por lo tanto, aquellos que no
          cumplan con esta condición deberán abstenerse de suministrar
          información para ser incluida en nuestras bases de datos.
          <br />
          <br />
          <b>Cookies</b>
          <br />
          <br />
          El usuario y el visitante del sitio Web de ACCESS INFORMATICA S.R.L.
          conoce y acepta que ACCESS INFORMATICA S.R.L. podrá utilizar un
          sistema de seguimiento mediante la utilización de cookies (las
          "Cookies"). Las Cookies son pequeños archivos que se instalan en el
          disco rígido, con una duración limitada en el tiempo que ayudan a
          personalizar los servicios. También ofrecemos ciertas funcionalidades
          que sólo están disponibles mediante el empleo de Cookies. Las Cookies
          se utilizan con el fin de conocer los intereses, el comportamiento y
          la demografía de quienes visitan o son usuarios de nuestro Sitio Web y
          de esa forma, comprender mejor sus necesidades e intereses y darles un
          mejor servicio o proveerle información relacionada. También usaremos
          la información obtenida por intermedio de las Cookies para analizar
          las páginas navegadas por el visitante o usuario, las búsquedas
          realizadas, mejorar nuestras iniciativas comerciales y promocionales,
          mostrar publicidad o promociones, banners de interés, noticias y
          perfeccionar nuestro servicio; también podremos utilizar Cookies para
          promover y hacer cumplir las reglas y seguridad del sitio.
          <br />
          <br />
          Utilizamos adicionalmente las Cookies para que el usuario no tenga que
          introducir su clave tan frecuentemente durante una sesión de
          navegación, la actividad del usuario y otros conceptos, siempre
          teniendo como objetivo de la instalación de las Cookies, el beneficio
          del usuario que la recibe.
          <br />
          <br />
          Se establece que la instalación, permanencia y existencia de las
          Cookies en el computador del usuario depende de su exclusiva voluntad
          y puede ser eliminada de su computador cuando el usuario así lo desee.
          El usuario puede, en cualquier momento, eliminar las cookies
          almacenadas, configurar el navegador para que solicite aprobación
          antes de almacenar cookies o directamente evitar que se almacenen
          cookies.
          <br />
          <br />
          Este procedimiento se realiza de forma diferente en los distintos
          navegadores y es necesario realizarlo en cada navegador que se
          utilice.
          <br />
          <br />
          <b>Spam</b>
          <br />
          <br />
          ACCESS INFORMATICA S.R.L. y sus usuarios no aceptan conductas
          consideradas "spamming", ya sea en opiniones, preguntas, respuestas
          y/o el envío no solicitado de correos electrónicos. Queda
          absolutamente prohibido el envío indiscriminado de mensajes de
          cualquier naturaleza entre los usuarios de ACCESS INFORMATICA S.R.L.
          <br />
          <br />
          ACCESS INFORMATICA S.R.L. podrá suspender o inhabilitar
          definitivamente a aquellos usuarios que violen esta política.
          <br />
          <br />
          <b>Orden de autoridades competentes - Requerimientos Legales</b>
          <br />
          <br />
          ACCESS INFORMATICA S.R.L. coopera con las autoridades competentes y
          con otros terceros para garantizar el cumplimiento de las leyes, por
          ejemplo en materia de protección de derechos de propiedad industrial e
          intelectual, prevención del fraude y otras materias.
          <br />
          <br />
          ACCESS INFORMATICA S.R.L. podrá revelar la Información Personal de sus
          usuarios bajo requerimiento de la autoridades judiciales o
          gubernamentales competentes para efectos de investigaciones conducidas
          por ellas, aunque no exista una orden ni una citación ejecutiva o
          judicial, o por ejemplo (y sin limitación a este supuesto) cuando se
          trate de investigaciones de carácter penal o de fraude o las
          relacionadas con piratería informática o la violación de derechos de
          autor. En tales situaciones, ACCESS INFORMATICA S.R.L. colaborará con
          las autoridades competentes con el fin de salvaguardar la integridad y
          la seguridad de la Comunidad y la de sus usuarios.
          <br />
          <br />
          ACCESS INFORMATICA S.R.L. puede (y los usuarios lo autorizan
          expresamente) comunicar cualquier Información Personal sobre sus
          usuarios con la finalidad de cumplir la normativa aplicable y cooperar
          con las autoridades competentes en la medida en que discrecionalmente
          lo entendamos necesario y adecuado en relación con cualquier
          investigación de un ilícito o un fraude, infracción de derechos de
          propiedad industrial o intelectual, u otra actividad que sea ilegal o
          que pueda exponer a ACCESS INFORMATICA S.R.L. o a sus usuarios a
          cualquier responsabilidad legal.
          <br />
          <br />
          Además, ACCESS INFORMATICA S.R.L.se reserva el derecho (y nos
          autorizas expresamente a ello) de comunicar información sobre sus
          usuarios a otros usuarios, entidades o terceros cuando haya motivos
          suficientes para considerar que la actividad de un usuario sea
          sospechosa de intentar o cometer un delito o intentar perjudicar a
          otras personas. Este derecho será utilizado por ACCESS INFORMATICA
          S.R.L. a su entera discreción cuando lo considere apropiado o
          necesario para mantener la integridad y la seguridad de la Comunidad y
          la de sus usuarios, para hacer cumplir los Términos y Condiciones y
          demás Políticas del sitio y a efectos de cooperar con la ejecución y
          cumplimiento de la ley. Este derecho será ejercido por ACCESS
          INFORMATICA S.R.L. independientemente que no exista una orden judicial
          o administrativa al efecto.
          <br />
          <br />
          <b>Seguridad</b>
          <br />
          <br />
          Almacenamiento de la información ACCESS INFORMATICA S.R.L. está
          obligado a cumplir con toda la normativa aplicable en materia de
          medidas de seguridad aplicables a la Información Personal.
          <br />
          <br />
          <b>Cambio en las preferencias de e-mails</b>
          <br />
          <br />
          Sin perjuicio que ACCESS INFORMATICA S.R.L. quiere mantener a sus
          usuarios actualizados en todo momento sobre promociones, novedades,
          cambios, etc. los usuarios pueden solicitar no recibir estas
          comunicaciones.
          <br />
          <br />
          Para hacer cualquier consulta, reclamo y/o petición relativa a tus
          datos personales, podrás hacerlo enviando un mail a
          info@accessinformatica.com.ar.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={onClose} color="primary" variant="contained">
          Al usar la aplicación acepto las Políticas de Privacidad y
          Confidencialidad
        </Button>
      </DialogActions>
    </Dialog>
  );
}
