import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDocuments } from "../../../../actions/accidentDashboard";
import {
  correctConnectivityErrorAction,
  getAllConnectivityErrors,
  getConnectivityErrors,
} from "../../../../actions/connectivityResponse";
import { postForm } from "../../../../actions/forms";
import { convertDateTimeToNet } from "../../../../utils/datetimeUtils";
import snackbarUtils from "../../../../utils/snackbarUtils";
import CircularLoading from "../../../commons/loadingData/circularLoading";
import Fpa from "./fpa";
import FpaAutorizationDialog from "./fpaAutorizationDialog";
import FpaConfirmDialog from "./fpaConfirmDialog";

const initialFpaModel = {
  nombreTipoFormulario: null,
  fechaHoraPedido: null,
  enInstitucionPropia: true,
  diagnostico: "",
  detalleAutorizacion: "",
  planTerapeutico: "",
  profesionalApellidoNombre: null,
  profesionalMnMp: "",
  especialidadProfesional: "",
  especialidadInforme: "",
  informacionSensible: false,
};

const FpaContainer = ({
  handleClose,
  isView,
  setLoaded,
  handleOpenConfirmCloseDialog,
  selectedDocument,
  fromErroresConectividad,
  fromErrorDialog,
}) => {
  const dispatch = useDispatch();

  const accident = useSelector((state) => state.accidentDashboard.accident);
  const saveForm = useSelector((state) => state.forms.saveForm);
  const model = useSelector((state) => state.forms.model);
  const form = useSelector((state) => state.forms.form);
  const professionals = useSelector((state) => state.forms.professionals);
  const prestacionesState = useSelector((state) => state.forms.prestaciones);
  const time = useSelector(
    (state) => state.connectivityResponse.connectivityErrors.time,
  );
  const [fpaModel, setFpaModel] = useState(null);
  const [files, setFiles] = useState([]);
  const [prestaciones, setPrestaciones] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openAutorizationDialog, setOpenAutorizationDialog] = useState(false);
  const [codigoAutorizacion, setCodigoAutorizacion] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [loading, setLoading] = useState(false);
  const isLoading =
    loading ||
    model.isFetching ||
    saveForm.isFetching ||
    form.isFetching ||
    professionals.isFetching ||
    (!isView && !fpaModel) ||
    (isView && !prestacionesState.data);

  useEffect(() => {
    if (isView && !form.isFetching) {
      setFiles(form.data.form.adjuntos);
      setPrestaciones(form.data.form.prestaciones);
    }
  }, [form.isFetching]);

  useEffect(() => {
    if (!model.isFetching && !isView) {
      setFpaModel({
        ...initialFpaModel,
        diagnostico: model.data.fields.diagnostico.options
          ? model.data.fields.diagnostico.options
          : initialFpaModel.diagnostico,
        profesionalApellidoNombre: model.data.fields.profesionalApellidoNombre
          .options
          ? model.data.fields.profesionalApellidoNombre.options
          : initialFpaModel.profesionalApellidoNombre,
        nombreTipoFormulario:
          model.data.fields.tiposFormulario.options.length === 1
            ? model.data.fields.tiposFormulario.options[0].nombre
            : initialFpaModel.nombreTipoFormulario,
        especialidadInforme: model.data.fields?.especialidadInforme.options
          ? model.data.fields?.especialidadInforme.options
          : initialFpaModel.especialidadInforme,
        informacionSensible: model.data.fields?.informacionSensible.options
          ? model.data.fields?.informacionSensible.options
          : initialFpaModel.informacionSensible,
      });
    }
  }, [model.isFetching]);

  useEffect(() => {
    if (!isLoading && !!setLoaded) {
      setLoaded(true);
    }
  }, [
    model.isFetching,
    saveForm.isFetching,
    form.isFetching,
    professionals.isFetching,
    prestacionesState.isFetching,
  ]);

  const handleCloseAutorizationDialog = () => {
    setOpenAutorizationDialog(false);
    setOpenConfirmDialog(true);
  };

  const handleOpenOtherFpa = () => {
    // Si el usuario hace click en "Guardar y Continuar", pero luego en el
    // segundo FPA cancela la operación, deberia ver el primer formulario
    // cargado en la lista de documentos.
    dispatch(getDocuments(accident.idSiniestro, accident.mlCloudCoreArtId));
    setPrestaciones([]);
    setFpaModel({
      ...formValues,
      detalleAutorizacion: "",
    });
    setLoading(false);
    handleCloseConfirmDialog();
    snackbarUtils.success("Formulario ingresado correctamente");
  };

  const handleConfirmAndClose = () => {
    handleClose(true, false);
    handleCloseConfirmDialog();
    snackbarUtils.success("Formulario ingresado correctamente");
  };

  const handleSubmit = (form) => {
    const filesAux = [];
    files.forEach((e) =>
      filesAux.push({ ...e, nombre: e.filename || e.nombre, base64: e.value }),
    );
    setFormValues(form);
    const model = {
      ...form,
      numeroSiniestroPrestador: accident.idSiniestro,
      adjuntos: filesAux,
      prestaciones: prestaciones,
      fechaHoraPedido: convertDateTimeToNet(form.fechaHoraPedido),
    };
    if (fromErroresConectividad) {
      model.numeroSiniestroPrestador = selectedDocument.siniestro;
    }
    setLoading(true);
    dispatch(postForm("fpa", model, accident.mlCloudCoreArtId))
      .then((res) => {
        if (!isView && !fromErrorDialog && !fromErroresConectividad) {
          if (res.value.data.codigoAutorizacion !== "0") {
            setOpenAutorizationDialog(true);
            setCodigoAutorizacion(res.value.data.codigoAutorizacion);
          } else {
            setOpenConfirmDialog(true);
          }
        } else {
          handleClose(true, false);
        }

        if (fromErrorDialog) {
          dispatch(
            correctConnectivityErrorAction(
              selectedDocument.idRespError,
              accident.mlCloudCoreArtId,
            ),
          ).then((res) => {
            dispatch(
              getDocuments(accident.idSiniestro, accident.mlCloudCoreArtId),
            );
            dispatch(
              getConnectivityErrors(
                selectedDocument.nroTransaccion,
                accident.mlCloudCoreArtId,
              ),
            ).then((res) => handleClose(true, false));
          });
        } else if (fromErroresConectividad) {
          dispatch(
            correctConnectivityErrorAction(
              selectedDocument.idRespError,
              accident.mlCloudCoreArtId,
            ),
          )
            .then((res) => {
              dispatch(getAllConnectivityErrors(time));
            })
            .then((res) => handleClose(true, false));
        }
      })
      .catch(() => handleClose(true, true));
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  return (
    <div style={{ width: "100%" }}>
      {isLoading ? (
        <div style={{ marginTop: 100 }}>
          <CircularLoading />
        </div>
      ) : isView ? (
        <Fpa
          handleClose={handleClose}
          files={files}
          setFiles={setFiles}
          handleSubmit={handleSubmit}
          model={form.data.model}
          fpaModel={form.data.form}
          roles={form.data.roles}
          selectedDocument={selectedDocument}
          prestaciones={prestaciones}
          setPrestaciones={setPrestaciones}
          fromErrorDialog={fromErrorDialog}
          fromErroresConectividad={fromErroresConectividad}
          isView={isView}
        />
      ) : (
        <Fpa
          handleClose={handleOpenConfirmCloseDialog}
          handleSubmit={handleSubmit}
          files={files}
          setFiles={setFiles}
          model={model.data}
          fpaModel={fpaModel}
          prestaciones={prestaciones}
          setPrestaciones={setPrestaciones}
          isView={isView}
        />
      )}
      <FpaConfirmDialog
        handleClose={handleCloseConfirmDialog}
        open={openConfirmDialog}
        title="CONFIRMAR FORMULARIO"
        text="¿Desea cargar un nuevo pedido de autorización?"
        handleConfirmarYFinalizar={handleConfirmAndClose}
        handleConfirmarYContinuar={handleOpenOtherFpa}
      />
      <FpaAutorizationDialog
        open={openAutorizationDialog}
        title="PEDIDO DE AUTORIZACION"
        handleSubmit={handleCloseAutorizationDialog}
        codigoAutorizacion={codigoAutorizacion}
      />
    </div>
  );
};

export default FpaContainer;
