import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccidentsInTreatment,
  getPendingAuthorizations,
} from "../../../actions/dashboard";
import CardContainer from "../../commons/dashboard/cardContainer";
import MyResponsivePie from "../../commons/dashboard/myResponsivePie";

const useStyles = makeStyles({
  title: {
    flex: 1,
    display: "flex",
    height: "40px",
    margin: "13px",
    padding: "10px 10px 10px 20px",
    color: "#808080d1",
    backgroundColor: "#80808029",
    borderRadius: "1px",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 500,
    fontSize: "17px",
  },
});

const DashboardContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const accidentsInTreatment = useSelector(
    (state) => state.dashboard.accidentsInTreatment,
  );
  const pendingAuthorizations = useSelector(
    (state) => state.dashboard.pendingAuthorizations,
  );

  return (
    <div style={{ backgroundColor: "#f5f6fa" }}>
      <div className={classes.title}>ACTUALIDAD</div>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center">
        <CardContainer
          text="SINIESTROS EN TRATAMIENTO POR ART"
          component={<MyResponsivePie data={accidentsInTreatment.data} />}
          isFetching={accidentsInTreatment.isFetching}
          fetchData={() => dispatch(getAccidentsInTreatment())}
          didInvalidate={accidentsInTreatment.didInvalidate}
          name="accidentsInTreatment"
        />

        <CardContainer
          text="PEDIDOS DE AUTORIZACION PENDIENTE POR ART"
          component={<MyResponsivePie data={pendingAuthorizations.data} />}
          isFetching={pendingAuthorizations.isFetching}
          fetchData={() => dispatch(getPendingAuthorizations())}
          didInvalidate={pendingAuthorizations.didInvalidate}
          name="pendingAuthorizations"
        />
      </Grid>
    </div>
  );
};

export default DashboardContainer;
