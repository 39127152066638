import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import FacadeAccidentCards from "./facadeAccidentCards";
import OpenAccidentCards from "./openAccidentCards";
import CircularLoading from "../../../../commons/loadingData/circularLoading";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 40,
    padding: 8,
    paddingLeft: 30,
    paddingRight: 60,
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    color: "white",
  },
  closeIcon: {
    position: "absolute",
    right: 10,
    color: "white",
    cursor: "pointer",
    width: 25,
    height: 25,
    marginTop: 25,
    marginBottom: 25,
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    width: 700,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: "#f5f5f5",
  },
  emptyState: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: 250,
  },
}));

const AccidentsFacadeAndOpen = ({
  open,
  handleClose,
  openAccidents,
  facadeAccidents,
  handleView,
  handleAdd,
  handleEdit,
  loading,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      disableBackdropClick={true}>
      <div className={classes.titleContainer}>
        <Typography className={classes.title} variant="h6">
          {openAccidents.length !== 0
            ? openAccidents[0].trabajador
            : facadeAccidents.length !== 0
            ? `${facadeAccidents[0].apellidoTrabajador || ""}, ${
                facadeAccidents[0].nombreTrabajador || ""
              }`
            : ""}
        </Typography>
        <Typography className={classes.title} variant="h6">
          {openAccidents.length !== 0 ? openAccidents[0].art : ""}
        </Typography>
        <Tooltip title="Cerrar">
          <Close onClick={handleClose} className={classes.closeIcon} />
        </Tooltip>
      </div>
      <DialogContent className={classes.cardContainer}>
        {loading ? (
          <div style={{ flex: 1, marginTop: 80, marginBottom: 100 }}>
            <CircularLoading />
          </div>
        ) : openAccidents.length === 0 && facadeAccidents.length === 0 ? (
          <div className={classes.emptyState}>
            <Typography variant="h6">
              No se encontraron siniestros para los datos ingresados
            </Typography>
            <Typography
              style={{ marginTop: 20, marginBottom: 80 }}
              variant="subtitle1">
              Consulte con la ART
            </Typography>
            <Button variant="contained" color="primary" onClick={handleClose}>
              Cerrar
            </Button>
          </div>
        ) : (
          <div>
            <FacadeAccidentCards
              accidents={facadeAccidents}
              handleAdd={handleAdd}
              handleEdit={handleEdit}
            />
            <OpenAccidentCards
              accidents={openAccidents}
              handleView={handleView}
            />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AccidentsFacadeAndOpen;
