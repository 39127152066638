import { Chip, makeStyles } from "@material-ui/core";
import MaterialTable from "material-table";
import React from "react";
import { useSelector } from "react-redux";
import ProviderInUser from "../../ui/users/providerInUser";
import { localization, tableStyles } from "./config/tableConfig";
import TitleContainer from "./utilsTable/titleWithFetchData";

const useStyles = makeStyles(() => ({
  chip: {
    marginRight: 5,
    marginTop: 2.5,
    marginBottom: 2.5,
  },
  empty: {
    fontSize: "0.75rem",
    color: "rgba(0, 0, 0, 0.54)",
  },
}));

const UsersTable = ({
  data,
  loading,
  title,
  noRefresh,
  fetchData,
  handleOpenDelete,
  handleImpersonate,
}) => {
  const classes = useStyles();

  const esSoporte = useSelector(
    (state) => state.auth.roles?.tipo === "Soporte",
  );

  const columns = [
    {
      title: "Usuario",
      field: "email",
    },
    {
      title: "Nombre completo",
      render: (rowData) => {
        return `${rowData.apellido}, ${rowData.nombre}`;
      },
    },
    {
      title: "Rol",
      render: (rowData) => {
        return rowData.roles.map((rol) => rol);
      },
    },
    {
      title: "Prestador",
      render: (rowData) => {
        if (rowData.prestadores.length > 0) {
          return rowData.prestadores.map((item) => (
            <Chip
              key={item.id}
              label={item.nombre}
              disabled
              className={classes.chip}
            />
          ));
        } else {
          return (
            <div className={classes.empty}>
              <p>Sin prestadores asociados</p>
            </div>
          );
        }
      },
    },
    {
      title: "Art",
      render: (rowData) => {
        return rowData.arts.map((art) => art.nombre);
      },
    },
  ];

  return (
    <MaterialTable
      columns={columns}
      data={data}
      isLoading={loading}
      style={tableStyles.container}
      title={
        <TitleContainer
          title={title}
          noRefresh={noRefresh}
          fetchData={fetchData}
        />
      }
      actions={[
        (rowData) => {
          const esPrestador =
            rowData.roles.find((r) => r === "PRESTADOR") &&
            rowData.prestadores.length !== 0;
          return {
            icon: "supervised_user_circle",
            tooltip: esPrestador ? "Impersonar Usuario" : "",
            onClick: (event, rowData) => handleImpersonate(rowData),
            disabled: !esPrestador,
          };
        },
        {
          icon: "delete",
          tooltip: "Eliminar Usuario",
          onClick: (event, rowData) => handleOpenDelete(rowData),
          disabled: esSoporte,
        },
      ]}
      options={{
        actionsColumnIndex: -1,
        rowStyle: tableStyles.rowStyle,
        headerStyle: tableStyles.headerStyle,
        pageSize: 10,
        search: false,
        filtering: true,
      }}
      localization={{
        ...localization,
        toolbar: {
          searchPlaceholder: "Buscar...",
        },
        body: {
          emptyDataSourceMessage: "No hay usuarios que mostrar",
          filterRow: {
            filterTooltip: "Filtrar",
          },
        },
      }}
      detailPanel={[
        {
          tooltip: "Más Información",
          render: (rowData) => {
            if (rowData.roles.find((r) => r === "PRESTADOR"))
              return (
                <div style={{ backgroundColor: "#fafafa", padding: 10 }}>
                  <ProviderInUser user={rowData} refresh={fetchData} />
                </div>
              );
            else return null;
          },
        },
      ]}
    />
  );
};

export default UsersTable;
