import React from "react";
import { makeStyles, Paper, Typography } from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-around",
    borderRadius: 5,
  },
  label: {
    display: "flex",
    alignItems: "center",
  },
}));

const styles = {
  icon: {
    height: 15,
    width: 15,
    marginRight: 5,
  },
  noInformados: {
    color: "red",
  },
  ausentes: {
    color: "black",
  },
  presentes: {
    color: "blue",
  },
  programados: {
    color: "green",
  },
  anulados: {
    color: "grey",
  },
};

const CalendarLabel = () => {
  const classes = useStyles();

  return (
    <Paper elevation={2} className={classes.container}>
      <div className={classes.label}>
        <FiberManualRecord style={{ ...styles.icon, ...styles.noInformados }} />
        <Typography variant="caption">No Informados</Typography>
      </div>
      <div className={classes.label}>
        <FiberManualRecord style={{ ...styles.icon, ...styles.ausentes }} />
        <Typography variant="caption">Ausentes</Typography>
      </div>
      <div className={classes.label}>
        <FiberManualRecord style={{ ...styles.icon, ...styles.presentes }} />
        <Typography variant="caption">Presentes</Typography>
      </div>
      <div className={classes.label}>
        <FiberManualRecord style={{ ...styles.icon, ...styles.programados }} />
        <Typography variant="caption">Programados</Typography>
      </div>
      <div className={classes.label}>
        <FiberManualRecord style={{ ...styles.icon, ...styles.anulados }} />
        <Typography variant="caption">Anulados</Typography>
      </div>
    </Paper>
  );
};

export default CalendarLabel;
