import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFormProfessionalSpecialties,
  setFormProfessionals,
  updateFormProfessionals,
} from "../../../../actions/forms";
import AddProfessional from "./addProfessional";
import Professional from "./professional";
import SpecialtyForm from "./specialtyForm";

const useStyles = makeStyles((theme) => ({
  professionalControl: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  button: {
    width: 40,
    height: 40,
  },
}));

const ProfessionalControl = ({
  disabled,
  fields,
  setFieldValue,
  profesionalPreCarga,
  error,
  setError,
  especialidadesProfesional,
  values,
  habilitarEspecialidadInforme,
  isView,
  selectedDocument,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const accident = useSelector((state) => state.accidentDashboard.accident);
  const professionals = useSelector((state) => state.forms.professionals);
  const professionalSpecialties = useSelector(
    (state) => state.forms.professionalSpecialties,
  );

  const [openProfessional, setOpenProfessional] = useState(false);
  const [edit, setEdit] = useState(false);
  const [errorProfessionalsDuplicated, setErrorProfessionalsDuplicated] =
    useState(false);
  const [especialidadesInforme, setEspecialidadesInforme] = useState([]);
  const [professionalModel, setProfessionalModel] = useState({
    profesionalApellidoNombre: "",
    matricula: "",
    especialidad: "",
    id: "",
  });

  const handleOpenEdit = () => {
    setEdit(true);
    setOpenProfessional(true);
  };

  const handleOpenProfessional = () => {
    setOpenProfessional(true);
  };

  const handleClose = () => {
    setOpenProfessional(false);
    setEdit(false);
    setErrorProfessionalsDuplicated(false);
    setError(false);
  };

  const setValuesInProfessionalModel = (values) => {
    if (!values) {
      setProfessionalModel({
        profesionalApellidoNombre: "",
        matricula: "",
        especialidad: "",
        id: "",
      });
      return;
    }

    setProfessionalModel({
      profesionalApellidoNombre:
        values.nombre || values.profesionalApellidoNombre,
      matricula: values.matricula || "",
      especialidad: values.especialidad || "",
      id: values.id,
    });
  };

  const setearEspecialidadesInforme = (
    profesionalApellidoNombre,
    newProfessional,
  ) => {
    if (!habilitarEspecialidadInforme) return;

    //Limpio el estado especialidadInforme con un array vacio
    setEspecialidadesInforme([]);

    if (!profesionalApellidoNombre) {
      return;
    }

    const profesional = newProfessional
      ? newProfessional
      : professionals.data.find(
          (p) =>
            p.nombre.toUpperCase() === profesionalApellidoNombre.toUpperCase(),
        );

    //Array Auxiliar donde voy a colocar los match entre los id de especialidades que trae el profesional y el objeto de cada especialidad
    const arr = [];

    //Recorro el array de especialidades y las matcheo con el array de especialidades que posee el profesional
    profesional.especialidades.forEach((ep) => {
      const match = especialidadesProfesional.options.find(
        (e) =>
          e.id.toUpperCase().trim() === ep.especialidad.toUpperCase().trim(),
      );
      match && arr.push(match);
    });

    //Busco la especialidad principal del profesional dentro del array auxiliar
    const especialidadPrincipal = arr.find(
      (ep) =>
        ep.id.toUpperCase().trim() ===
        profesional.especialidad.toUpperCase().trim(),
    );

    if (!especialidadPrincipal) {
      //Sino encontre la especilidad principal en el auxiliar lo busco en el de todas las especialidades y lo pusheo al array aux
      const ep = especialidadesProfesional.options.find(
        (ep) =>
          ep.id.toUpperCase().trim() ===
          profesional.especialidad.toUpperCase().trim(),
      );
      ep && arr.push(ep);
    }

    setEspecialidadesInforme(arr);
  };

  const handleCancel = () => {
    setValuesInProfessionalModel();
    setFieldValue("profesionalApellidoNombre", "");
    setFieldValue("profesionalMnMp", "");
    setFieldValue("especialidadProfesional", "");
    setearEspecialidadesInforme();
    setFieldValue("informacionSensible", false);
    handleClose();
  };

  const handleSubmit = (pro) => {
    if (!edit) {
      setErrorProfessionalsDuplicated(false);
      if (
        professionals.data.some(
          (p) =>
            p.nombre.toUpperCase() ===
            pro.profesionalApellidoNombre.toUpperCase(),
        )
      ) {
        setErrorProfessionalsDuplicated(true);
        return;
      }
      const newProfessional = {
        nombre: pro.profesionalApellidoNombre,
        especialidad: pro.especialidad,
        nombreEspecialidad: pro.nombreEspecialidad,
        especialidades: [{ especialidad: pro.especialidad }],
        matricula: pro.matricula,
        id: "",
      };
      dispatch(setFormProfessionals(newProfessional));
      setearEspecialidadesInforme(
        pro.profesionalApellidoNombre,
        newProfessional,
      );
    } else {
      dispatch(updateFormProfessionals(pro));
      setearEspecialidadesInforme(pro.profesionalApellidoNombre);
    }
    setProfessionalModel(pro);
    setFieldValue("profesionalApellidoNombre", pro.profesionalApellidoNombre);
    setFieldValue("profesionalMnMp", pro.matricula);
    setFieldValue("especialidadProfesional", pro.especialidad);
    setFieldValue("especialidadInforme", pro.especialidad);
    handleClose();
  };

  useEffect(() => {
    if (profesionalPreCarga) {
      const profesional = professionals.data.find(
        (p) => p.nombre.toUpperCase() === profesionalPreCarga.toUpperCase(),
      );
      if (profesional) {
        setFieldValue("profesionalApellidoNombre", profesional.nombre);
        setFieldValue("profesionalMnMp", profesional.matricula || "");
        setFieldValue(
          "especialidadProfesional",
          profesional.especialidad || "",
        );
        setValuesInProfessionalModel(profesional);
        setearEspecialidadesInforme(profesional.nombre);
        if (!profesional.especialidad || !profesional.matricula) setError(true);
      }
    }
  }, [profesionalPreCarga]);

  useEffect(() => {
    if (
      openProfessional &&
      !professionalSpecialties.data.length &&
      !especialidadesProfesional.options
    ) {
      dispatch(getFormProfessionalSpecialties(accident.mlCloudCoreArtId));
    }
  }, [openProfessional]);

  return (
    <div className={classes.professionalControl}>
      <Professional
        disabled={disabled}
        fields={fields}
        setFieldValue={setFieldValue}
        setValuesInProfessionalModel={setValuesInProfessionalModel}
        setearEspecialidadesInforme={setearEspecialidadesInforme}
        professionalModel={professionalModel}
        handleOpenProfessional={handleOpenProfessional}
        handleOpenEdit={handleOpenEdit}
        setEdit={setEdit}
        classes={classes}
      />
      <AddProfessional
        open={openProfessional}
        especialidadesProfesional={
          especialidadesProfesional.options &&
          professionalSpecialties.data.length === 0
            ? especialidadesProfesional.options
            : professionalSpecialties.data
        }
        handleSubmit={handleSubmit}
        professionalModel={professionalModel}
        handleCancel={handleCancel}
        edit={edit}
        errorProfessionalsDuplicated={errorProfessionalsDuplicated}
      />
      {/* Si no está editando, no debería aparecer el error */}
      {error && !isView && (
        <Typography color="error">
          Debe editar el profesional para completar toda la información
        </Typography>
      )}
      {habilitarEspecialidadInforme && especialidadesInforme.length !== 0 && (
        <SpecialtyForm
          disabled={disabled}
          fields={fields}
          setFieldValue={setFieldValue}
          especialidadesInforme={especialidadesInforme}
          values={values}
          isView={isView}
          especialidadesProfesional={especialidadesProfesional}
        />
      )}
    </div>
  );
};

export default ProfessionalControl;
