import { makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import FormikTextField from "../../commons/formik/formikTextField";
import FormikActions from "../formik/formikActions";
import FormikSelect from "../formik/formikSelect";

const useStyles = makeStyles({});

const validation = Yup.object({
  artId: Yup.string().required("Campo obligatorio"),
  idPrestadorArt: Yup.number()
    .integer("Identificador inválido")
    .required("Campo obligatorio")
    .min(0, "Identificador inválido"),
});

const EditArtAdhesionDialog = ({
  adhesion,
  artsNotAdhered,
  open,
  handleClose,
  addingNewAdhesion,
  handleSubmit,
  loading,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
      disableBackdropClick={true}>
      {loading ? (
        <CircularProgress
          style={{ marginLeft: 200, marginTop: 100, marginBottom: 100 }}
          size={40}
        />
      ) : (
        <div>
          <DialogTitle>
            {addingNewAdhesion ? "Nueva adhesión" : "Editar adhesión"}
          </DialogTitle>
          <Formik
            initialValues={adhesion}
            validationSchema={validation}
            onSubmit={(values) => {
              handleSubmit(values, addingNewAdhesion);
            }}>
            <Form>
              <DialogContent className={classes.contentContainer}>
                {addingNewAdhesion ? (
                  <FormikSelect name="artId" label="ART">
                    {artsNotAdhered &&
                      artsNotAdhered.map((art) => {
                        return (
                          <MenuItem key={art.id} value={art.id}>
                            {art.nombre}
                          </MenuItem>
                        );
                      })}
                  </FormikSelect>
                ) : (
                  <FormikSelect
                    name="nombre"
                    value={adhesion.nombre}
                    label="ART"
                    disabled>
                    <MenuItem value={adhesion.nombre}>
                      {adhesion.nombre}
                    </MenuItem>
                  </FormikSelect>
                )}
                <FormikTextField
                  margin="dense"
                  name="idPrestadorArt"
                  label="Identificador"
                  type="number"
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <FormikActions handleCancel={handleClose} />
              </DialogActions>
            </Form>
          </Formik>
        </div>
      )}
    </Dialog>
  );
};

export default EditArtAdhesionDialog;
