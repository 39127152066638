import MaterialTable from "material-table";
import React from "react";
import { localization, tableStyles } from "./config/tableConfig";
import TitleContainer from "./utilsTable/titleWithFetchData";

const FilesTable = ({
  data,
  loading,
  fetchData,
  handleEdit,
  handleDownload,
}) => {
  const columns = [
    {
      title: "Descripción",
      field: "descripcion",
      render: (rowData) => rowData.descripcion,
    },
  ];

  return (
    <MaterialTable
      columns={columns}
      data={data}
      isLoading={loading}
      style={{ ...tableStyles.container }}
      locale="es"
      title={
        <TitleContainer title="Archivos Disponibles" fetchData={fetchData} />
      }
      options={{
        actionsColumnIndex: -1,
        rowStyle: tableStyles.rowStyle,
        headerStyle: tableStyles.headerStyle,
        pageSize: 10,
        search: false,
        filtering: true,
        exportButton: false,
      }}
      actions={[
        {
          icon: "edit",
          tooltip: "Editar Archivo",
          onClick: (event, rowData) => handleEdit(rowData),
        },
        {
          icon: "download",
          tooltip: "Descargar Archivo",
          onClick: (event, rowData) => handleDownload(rowData),
        },
      ]}
      localization={{
        ...localization,
        body: {
          emptyDataSourceMessage: "No hay Archivos para mostrar",
          filterRow: {
            filterTooltip: "Filtrar",
          },
        },
      }}
    />
  );
};

export default FilesTable;
