import { CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "auto",
    height: 150,
    border: "1px solid #000",
    borderRadius: "6px",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  paperProgress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    width: 400,
    height: 200,
    border: "1px solid #000",
    borderRadius: "6px",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:focus": {
      outline: "none",
    },
  },
  button: {
    width: "18px",
    height: "33px",
    margin: "10px 0px 0px 10px",
  },
  progress: {
    width: "35px !important",
    height: "35px !important",
    margin: 3,
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
}));

export default function ModalTransferList({
  handleClose,
  open,
  art,
  handleConfirmed,
}) {
  const classes = useStyles();

  const loading = useSelector((state) => state.art.requestAdhesion.isFetching);

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      disableAutoFocus>
      {loading ? (
        <div className={classes.paperProgress}>
          <CircularProgress className={classes.progress} color="secondary" />
        </div>
      ) : (
        <div className={classes.paper}>
          <span
            style={{ fontSize: "initial", fontFamily: "Roboto, sans-serif" }}>
            ¿Está seguro que quiere adherirse a la ART <b>{art}</b>?
          </span>

          <div className={classes.buttonsContainer}>
            <Button
              variant="contained"
              disableElevation
              color="default"
              onClick={handleClose}
              className={classes.button}
              disabled={loading}>
              No
            </Button>
            <Button
              variant="contained"
              disableElevation
              color="primary"
              onClick={handleConfirmed}
              className={classes.button}>
              Sí
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
}
