import MaterialTable from "material-table";
import React, { useState } from "react";
import { localization, tableStyles } from "./config/tableConfig";
import TitleContainer from "./utilsTable/titleWithFetchData";

const ArtInProviderTable = ({
  data,
  handleOpenEdit,
  handleDelete,
  handleOpenConfirm,
}) => {
  const [columnObject] = useState([
    {
      title: "Nombre",
      field: "nombre",
    },
    {
      title: "Identificador",
      field: "idPrestadorArt",
    },
    {
      title: "Estado",
      field: "adherida",
      lookup: { 0: "Adherida", 2: "Pendiente" },
      render: (rowData) => {
        switch (rowData.adherida) {
          case 0:
            return "Adherida";
          case 1:
            return "No Adherida";
          case 2:
            return "Pendiente";
          default:
            return "";
        }
      },
    },
  ]);

  return (
    <MaterialTable
      columns={columnObject}
      data={data}
      style={{ ...tableStyles.container, backgroundColor: "#f7faff" }}
      locale="es"
      title={<TitleContainer title="Adhesiones con Arts" />}
      options={{
        actionsColumnIndex: -1,
        rowStyle: tableStyles.rowStyle,
        headerStyle: { ...tableStyles.headerStyle, backgroundColor: "#f7faff" },
        pageSize: 5,
        search: false,
        filtering: true,
        exportButton: false,
      }}
      actions={[
        (rowData) => ({
          icon: "done",
          tooltip: "Confirmar Adhesión",
          onClick: (event, rowData) => handleOpenConfirm(rowData),
          hidden: rowData.adherida === 0 ? true : false,
        }),
        {
          icon: "edit",
          tooltip: "Editar Adhesión",
          onClick: (event, rowData) => handleOpenEdit(rowData),
        },
        {
          icon: "delete",
          tooltip: "Eliminar Adhesión",
          onClick: (event, rowData) => handleDelete(rowData),
        },
      ]}
      localization={{
        ...localization,
        body: {
          emptyDataSourceMessage: "No hay adhesiones que mostrar",
          filterRow: {
            filterTooltip: "Filtrar",
          },
        },
      }}
    />
  );
};
export default ArtInProviderTable;
