import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAppointmentsCalendar,
  getAvailableDocumentsToAdd,
  getDocuments,
} from "../../../../../actions/accidentDashboard";
import { getAccidents } from "../../../../../actions/accidents";
import { clearIdTurnos } from "../../../../../actions/appointments";
import {
  clearFpt,
  clearIdTurnosForms,
  clearIdrInView,
  setCloseAddForm,
} from "../../../../../actions/forms";
import snackbarUtils from "../../../../../utils/snackbarUtils";
import ConfirmDialog from "../../../../commons/dialog/confirmDialog";
import CardTitle from "../cardTitle";
import AddDocumentationCards from "./addDocumentationCards";
import AddFormContainer from "./addFormContainer";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
}));

const AddDocumentation = ({ formToAdd, openAddForm, handleOpenAddForm }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const fpt = useSelector((state) => state.forms.fpt);
  const idr = useSelector((state) => state.forms.idr);
  const accident = useSelector((state) => state.accidentDashboard.accident);
  const documentation = useSelector(
    (state) => state.accidentDashboard.availableDocumentsToAdd,
  );

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(
      getAvailableDocumentsToAdd(
        accident.idSiniestro,
        accident.mlCloudCoreArtId,
      ),
    );
  }, []);

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };
  const handleOpenConfirmDialog = () => {
    setConfirmDialogOpen(true);
  };

  const handleCloseAddForm = (formSubmited, error) => {
    dispatch(clearIdTurnosForms());
    dispatch(clearIdTurnos());
    if (formSubmited === true) {
      dispatch(
        getAvailableDocumentsToAdd(
          accident.idSiniestro,
          accident.mlCloudCoreArtId,
        ),
      );
      dispatch(getDocuments(accident.idSiniestro, accident.mlCloudCoreArtId));
      dispatch(
        getAppointmentsCalendar(
          accident.idSiniestro,
          accident.mlCloudCoreArtId,
        ),
      );
      dispatch(getAccidents());
      if (!error) {
        snackbarUtils.success("Formulario ingresado correctamente");
      }
    }
    handleCloseConfirmDialog();
    dispatch(setCloseAddForm());
    if (fpt.isOpen) {
      dispatch(clearFpt());
    } else if (idr.isOpen) {
      dispatch(clearIdrInView());
    }
  };

  return (
    <>
      <div className={classes.container}>
        <CardTitle title="AGREGAR DOCUMENTACIÓN" color="primary" />
        <AddDocumentationCards
          data={documentation.data}
          loading={documentation.isFetching}
          handleOpenAddForm={handleOpenAddForm}
        />
        <AddFormContainer
          open={openAddForm}
          handleOpenConfirmDialog={handleOpenConfirmDialog}
          handleClose={handleCloseAddForm}
          form={formToAdd}
        />
      </div>
      <div>
        <ConfirmDialog
          open={confirmDialogOpen}
          handleClose={handleCloseConfirmDialog}
          handleConfirm={handleCloseAddForm}
          title="CERRAR VENTANA"
          text="¿Está seguro que desea cancelar esta operación? En caso de aceptar, se perderá la información ingresada"
        />
      </div>
    </>
  );
};

export default AddDocumentation;
