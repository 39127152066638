import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import MaterialTable from "material-table";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appointmentAssist } from "../../../../../../actions/accidentDashboard";
import { attendedAppointment } from "../../../../../../actions/appointments";
import snackbarUtils from "../../../../../../utils/snackbarUtils";
import {
  localization,
  tableStyles,
} from "../../../../../commons/table/config/tableConfig";

const useStyles = makeStyles((theme) => ({
  appointmentsContainer: {
    padding: 0,
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    color: "white",
    fontWeight: "bold",
    marginLeft: 10,
  },
  closeIcon: {
    color: "white",
    cursor: "pointer",
    width: 25,
    height: 25,
    position: "absolute",
    right: 0,
  },
  actionsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    zIndex: 2,
    backgroundColor: "white",
    boxShadow: "0px 0 5px lightGrey",
  },
  typographyTitle: {
    fontSize: 16,
    padding: 2,
    textAlign: "center",
  },
}));

const AppointmentDetailFromForm = ({
  open,
  handleClose,
  formTitle,
  appointmentsSelected,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const accident = useSelector((state) => state.accidentDashboard.accident);

  const [selectedAppointmentinGrid, setSelectedAppointmentinGrid] = useState(
    [],
  );

  const handleSubmitForm = () => {
    dispatch(appointmentAssist());
    handleClose();
  };

  const saveSelectedAppointment = (rowData) => {
    setSelectedAppointmentinGrid(() => {
      return rowData;
    });
  };

  const attended = (appointment) => {
    dispatch(
      attendedAppointment(
        appointment.idTurnoSiniestro,
        accident.mlCloudCoreArtId,
      ),
    );
  };

  const handleSubmitFormAndAppointment = () => {
    selectedAppointmentinGrid.forEach((a) => {
      attended(a);
    });
    dispatch(appointmentAssist());
    handleClose();
    setSelectedAppointmentinGrid([]);
    snackbarUtils.success("Turno marcado como concurrido correctamente");
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        disableBackdropClick={true}>
        <div className={classes.titleContainer}>
          <Typography className={classes.title}>TURNOS</Typography>
          <Tooltip title="Cerrar">
            <Close onClick={handleClose} className={classes.closeIcon} />
          </Tooltip>
        </div>
        <DialogContent className={classes.appointmentsContainer}>
          <MaterialTable
            title={
              <Typography className={classes.typographyTitle}>
                {formTitle}
              </Typography>
            }
            style={tableStyles.container}
            columns={[
              {
                title: "Día",
                render: (rowData) =>
                  moment(rowData.fechaHora).format("DD/MM/YYYY"),
              },
              {
                title: "Hora",
                render: (rowData) => moment(rowData.fechaHora).format("HH:mm"),
              },
              {
                title: "Profesional",
                render: (rowData) => rowData.profesionalApellidoNombre,
              },
              {
                title: "Especialidad",
                render: (rowData) => rowData.especialidadNombre,
              },
            ]}
            data={appointmentsSelected}
            options={{
              actionsColumnIndex: -1,
              headerStyle: tableStyles.headerStyle,
              rowStyle: tableStyles.rowStyle,
              search: false,
              selection: true,
            }}
            onSelectionChange={(rows) => saveSelectedAppointment(rows)}
            localization={{
              ...localization,
              body: {
                emptyDataSourceMessage: "No hay turnos que mostrar",
                filterRow: {
                  filterTooltip: "Filtrar",
                },
              },
            }}
          />
        </DialogContent>
        <div className={classes.actionsContainer}>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button
              onClick={() => handleSubmitForm()}
              color="primary"
              variant="contained"
              disabled={selectedAppointmentinGrid.length === 0 ? false : true}>
              Guardar Formulario
            </Button>
            <Button
              onClick={() => handleSubmitFormAndAppointment()}
              color="primary"
              variant="contained"
              disabled={selectedAppointmentinGrid.length === 0 ? true : false}>
              Guardar Formulario y Asistencia
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default AppointmentDetailFromForm;
