import MaterialTable from "material-table";
import React, { useState } from "react";
import { localization, tableStyles } from "./config/tableConfig";
import TitleContainer from "./utilsTable/titleWithFetchData";

const crudArtTable = ({
  tableIcons,
  arts,
  loading,
  fetchArts,
  handleOpenModalEditar,
  handleOpenModalEliminar,
}) => {
  const [columnObject] = useState([
    {
      title: "Razón social",
      field: "nombre",
      render: (rowData) => rowData.nombre,
    },
    {
      title: "Nombre corto",
      field: "nombreCorto",
      render: (rowData) => rowData.nombreCorto,
    },
  ]);

  return (
    <MaterialTable
      icons={tableIcons}
      columns={columnObject}
      data={arts}
      isLoading={loading}
      style={tableStyles.container}
      locale="es"
      title={<TitleContainer title="ARTs" fetchData={fetchArts} />}
      options={{
        actionsColumnIndex: -1,
        rowStyle: tableStyles.rowStyle,
        headerStyle: tableStyles.headerStyle,
        pageSize: 10,
        search: false,
        filtering: true,
        exportButton: false,
      }}
      actions={[
        {
          icon: "edit",
          tooltip: "Editar ART",
          onClick: (event, rowData) => handleOpenModalEditar(rowData),
        },
        {
          icon: "delete",
          tooltip: "Eliminar ART",
          onClick: (event, rowData) => handleOpenModalEliminar(rowData),
        },
      ]}
      localization={{
        ...localization,
        body: {
          emptyDataSourceMessage: "No hay ARTs que mostrar",
          filterRow: {
            filterTooltip: "Filtrar",
          },
        },
      }}
    />
  );
};
export default crudArtTable;
