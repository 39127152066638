import { FormControlLabel, MenuItem, Radio } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFormDiagnosis,
  getFormNaturalezasLesion,
  getFormZonasAfectadas,
} from "../../../../actions/forms";
import FormikActions from "../../../commons/formik/formikActions";
import FormikAutocomplete from "../../../commons/formik/formikAutocomplete";
import FormikCheckbox from "../../../commons/formik/formikCheckbox";
import FormikDatePicker from "../../../commons/formik/formikDatePicker";
import FormikDateTimePicker from "../../../commons/formik/formikDateTimePicker";
import FormikRadio from "../../../commons/formik/formikRadio";
import FormikSelect from "../../../commons/formik/formikSelect";
import FormikTextField from "../../../commons/formik/formikTextField";
import FormikTextFieldSpeech from "../../../commons/formik/formikTextFieldSpeech";
import AppointmentControl from "../appointmentControl/appointmentControl";
import DropZone from "../dropZone/dropZone";
import FormSectionDivider from "../formSectionDivider";
import { useStyles } from "../formStyle";
import { iamiValidation, validateRequiredFields } from "../formValidation";
import ProfessionalControl from "../professionalControl/professionalControl";

const Iami = ({
  handleClose,
  files,
  setFiles,
  handleSubmit,
  model,
  iamiModel,
  roles,
  selectedDocument,
  openAppointment,
  handleAppointmentSubmit,
  handleCloseAppointment,
  handleOpenAppointment,
  appointmentModel,
  fromErrorDialog,
  fromErroresConectividad,
  isView,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const accident = useSelector((state) => state.accidentDashboard.accident);
  const diagnosis = useSelector((state) => state.forms.diagnosis);
  const naturalezasLesion = useSelector(
    (state) => state.forms.naturalezasLesion,
  );
  const zonasAfectadas = useSelector((state) => state.forms.zonasAfectadas);

  const [professionalError, setProfessionalError] = useState(false);
  const [attachmentError, setAttachmentError] = useState(false);

  const fields = model.fields;
  const disabled =
    roles && !fromErrorDialog && !fromErroresConectividad
      ? !roles.editar
      : false;
  const fechaPrimeraCuracionMinDate =
    fields?.fechaPrimeraCuracion?.validations?.minDate ?? undefined;
  return (
    <Formik
      initialValues={iamiModel}
      validationSchema={() => iamiValidation(fechaPrimeraCuracionMinDate)}
      validate={(values) => validateRequiredFields(values, fields)}
      onSubmit={(values) => {
        if (model.configuration.requiresAttachment && files.length === 0) {
          setAttachmentError(true);
        } else if (!professionalError) {
          handleSubmit(values);
        }
      }}>
      {({ values, setFieldValue }) => (
        <Form className={classes.container}>
          <div className={classes.fieldContainer}>
            <FormSectionDivider title="Accidente" />
            {model.configuration.showTypeFormCombo && (
              <div className={classes.rowContainer}>
                <FormikSelect
                  name="nombreTipoFormulario"
                  label={`Tipo de Formulario Especifico ${
                    fields.nombreTipoFormulario.required ? "*" : ""
                  }`}
                  value={iamiModel.nombreTipoFormulario}
                  variant="outlined"
                  className={classes.input}
                  disabled={disabled}>
                  {fields.tiposFormulario.options.map((f) => {
                    return (
                      <MenuItem key={f.nombre} value={f.nombre}>
                        {f.formulario} - {f.nombre}
                      </MenuItem>
                    );
                  })}
                </FormikSelect>
              </div>
            )}
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikSelect
                  name="tipoSiniestro"
                  value={iamiModel.tipoSiniestro}
                  label={`Tipo de Siniestro ${
                    fields.tipoSiniestro.required ? "*" : ""
                  }`}
                  variant="outlined"
                  className={classes.input}
                  disabled={disabled}>
                  {fields.tipoSiniestro.options?.list?.map((ts) => (
                    <MenuItem value={ts.nombre} key={ts.id}>
                      {ts.nombre}
                    </MenuItem>
                  ))}
                </FormikSelect>
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikDateTimePicker
                  name="fechaHoraAccidente"
                  label={`Fecha Hora Accidente ${
                    fields.fechaHoraAccidente.required ? "*" : ""
                  }`}
                  inputVariant="outlined"
                  disableFuture
                  className={classes.input}
                  disabled={disabled}
                  autoComplete="off"
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikDatePicker
                  name="fechaHoraAbondonoTrabajo"
                  label={`Fecha en que abandonó el Trabajo ${
                    fields.fechaHoraAbondonoTrabajo.required ? "*" : ""
                  }`}
                  inputVariant="outlined"
                  disableFuture
                  className={classes.input}
                  disabled={disabled}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikDateTimePicker
                  name="fechaPrimeraCuracion"
                  label={`Fecha Primera Curación ${
                    fields.fechaPrimeraCuracion.required ? "*" : ""
                  }`}
                  inputVariant="outlined"
                  disableFuture
                  minDate={fechaPrimeraCuracionMinDate}
                  className={classes.input}
                  disabled={disabled}
                  autoComplete="off"
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <AppointmentControl
                  addAppointmentControl={
                    model.configuration.addAppointmentControl
                  }
                  appointmentModel={appointmentModel}
                  name="fechaHoraProximaConsulta"
                  handleAppointmentSubmit={handleAppointmentSubmit}
                  openAppointment={openAppointment}
                  handleOpenAppointment={handleOpenAppointment}
                  handleCloseAppointment={handleCloseAppointment}
                  especialidadesProfesional={fields.especialidadProfesional}
                  required={fields.fechaHoraProximaConsulta.required}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <FormikTextFieldSpeech
                id="comoOcurrioAccidente"
                name="comoOcurrioAccidente"
                label={`¿Cómo ocurrió el accidente? ${
                  fields.comoOcurrioAccidente.required ? "*" : ""
                }`}
                type="text"
                variant="outlined"
                multiline
                rows={3}
                rowsMax={10}
                className={classes.input}
                disabled={disabled}
              />
            </div>
            <div className={classes.rowContainer}>
              <FormikAutocomplete
                name="formaAccidente"
                label={`Forma del Accidente ${
                  fields.formaAccidente.required ? "*" : ""
                }`}
                variant="outlined"
                options={fields.formaAccidente.options}
                className={classes.input}
                disabled={disabled}
              />
            </div>
            <div className={classes.rowContainer}>
              <FormikAutocomplete
                name="agenteCausante"
                label={`Agente Material ${
                  fields.agenteCausante.required ? "*" : ""
                }`}
                variant="outlined"
                options={fields.agenteCausante.options}
                className={classes.input}
                disabled={disabled}
              />
            </div>
            <div className={classes.rowContainer}>
              <FormikTextFieldSpeech
                id="tratamiento"
                name="tratamiento"
                label={`Tratamiento ${fields.tratamiento.required ? "*" : ""}`}
                type="text"
                multiline
                rows={3}
                rowsMax={10}
                variant="outlined"
                className={classes.input}
                disabled={disabled}
              />
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikCheckbox
                  name="reagravacion"
                  label={`Reagravación ${
                    fields.reagravacion.required ? "*" : ""
                  }`}
                  labelPlacement="end"
                  disabled={disabled}
                />
              </div>
              {values.reagravacion && (
                <div className={classes.rightColumnContainer}>
                  <FormikAutocomplete
                    name="tipoReingreso"
                    label={`Tipo de Reingreso ${
                      values.reagravacion ? "*" : ""
                    }`}
                    variant="outlined"
                    options={fields.tipoReingreso.options}
                    className={classes.input}
                    disabled={disabled}
                  />
                </div>
              )}
            </div>
            {values.reagravacion && (
              <div className={classes.rowContainer}>
                <div className={classes.leftColumnContainer}>
                  <FormikDatePicker
                    name="fechaReagravacion"
                    label={`Fecha de Reagravación ${
                      values.reagravacion ? "*" : ""
                    }`}
                    inputVariant="outlined"
                    className={classes.input}
                    disabled={disabled}
                    autoComplete="off"
                  />
                </div>
                <div className={classes.rightColumnContainer}>
                  <FormikDatePicker
                    name="fechaAtencionReagravacion"
                    label={`Fecha Atención Reagravación ${
                      values.reagravacion ? "*" : ""
                    }`}
                    inputVariant="outlined"
                    className={classes.input}
                    disabled={disabled}
                    autoComplete="off"
                  />
                </div>
              </div>
            )}
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikCheckbox
                  name="atencionMedicaAnterior"
                  label={`Atención Médica Anterior ${
                    fields.atencionMedicaAnterior.required ? "*" : ""
                  }`}
                  labelPlacement="end"
                  disabled={disabled}
                />
              </div>
              {values.atencionMedicaAnterior && (
                <div className={classes.rightColumnContainer}>
                  <FormikTextField
                    name="centroAsistencial"
                    label={`Centro Asistencial ${
                      fields.centroAsistencial.required ? "*" : ""
                    }`}
                    type="text"
                    variant="outlined"
                    className={classes.input}
                    disabled={disabled}
                  />
                </div>
              )}
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikCheckbox
                  name="bajaLaboral"
                  label={`Baja Laboral ${
                    fields.bajaLaboral.required ? "*" : ""
                  }`}
                  labelPlacement="end"
                  disabled={disabled}
                />
              </div>
              {values.bajaLaboral && (
                <div className={classes.rightColumnContainer}>
                  <FormikTextField
                    name="diasEstimados"
                    label={`Días Estimados ${
                      fields.diasEstimados.required ? "*" : ""
                    }`}
                    type="number"
                    variant="outlined"
                    className={classes.input}
                    disabled={disabled}
                  />
                </div>
              )}
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikCheckbox
                  name="sugerenciaRechazo"
                  label={`Sugerencia Rechazo ${
                    fields.sugerenciaRechazo.required ? "*" : ""
                  }`}
                  labelPlacement="end"
                  disabled={disabled}
                />
              </div>
              {values.sugerenciaRechazo && (
                <div className={classes.rightColumnContainer}>
                  <FormikSelect
                    name="causaRechazo"
                    value={iamiModel.causaRechazo}
                    label={`Causa Rechazo ${
                      fields.causaRechazo.required ? "*" : ""
                    }`}
                    variant="outlined"
                    className={classes.input}
                    disabled={disabled}>
                    {fields.causaRechazo.options.map((cr) => (
                      <MenuItem value={cr} key={cr}>
                        {cr}
                      </MenuItem>
                    ))}
                  </FormikSelect>
                </div>
              )}
            </div>
            <div className={classes.rowContainer}>
              <FormikCheckbox
                name="suponeIncapacidad"
                label={`Supone Incapacidad ${
                  fields.suponeIncapacidad.required ? "*" : ""
                }`}
                labelPlacement="end"
                disabled={disabled}
              />
            </div>
            <div className={classes.rowContainer}>
              <FormikCheckbox
                name="r79"
                label={`¿Se encuadra dentro de los accidentes de la Res. 1021/2008 de la S.R.T.? ${
                  fields.r79.required ? "*" : ""
                }`}
                labelPlacement="end"
                disabled={disabled}
              />
            </div>
            <div className={classes.rowContainer}>
              <FormikRadio
                name="manoHabil"
                value={iamiModel.manoHabil}
                label={`Mano Hábil ${fields.manoHabil.required ? "*" : ""}`}
                disabled={disabled}
                row>
                {fields.manoHabil.options.map((mh) => (
                  <FormControlLabel
                    value={mh}
                    control={<Radio />}
                    label={mh}
                    labelPlacement="end"
                    key={mh}
                  />
                ))}
              </FormikRadio>
            </div>
            <FormSectionDivider title="Diagnóstico" />
            <div className={classes.rowContainer}>
              <FormikAutocomplete
                name="diagnosticoOMS"
                label={`Diagnóstico Código OMS ${
                  fields.diagnosticoOMS.required ? "*" : ""
                }`}
                variant="outlined"
                options={diagnosis.data}
                getOptions={() =>
                  dispatch(getFormDiagnosis(accident.mlCloudCoreArtId))
                }
                className={classes.input}
                renderOption={(option) => `${option.id} - ${option.nombre}`}
                filterOptions={(option) => option.id + option.nombre}
                disabled={disabled}
              />
            </div>
            <div className={classes.rowContainer}>
              <FormikTextFieldSpeech
                id="diagnostico"
                name="diagnostico"
                label={`Descripción del diagnóstico ${
                  fields.diagnostico.required ? "*" : ""
                }`}
                type="text"
                variant="outlined"
                multiline
                rows={3}
                rowsMax={10}
                className={classes.input}
                disabled={disabled}
              />
            </div>
            <div className={classes.rowContainer}>
              <FormikAutocomplete
                name="naturalezaLesion"
                label={`Naturaleza de la Lesión ${
                  fields.naturalezaLesion.required ? "*" : ""
                }`}
                variant="outlined"
                options={naturalezasLesion.data}
                getOptions={() =>
                  dispatch(getFormNaturalezasLesion(accident.mlCloudCoreArtId))
                }
                className={classes.input}
                disabled={disabled}
              />
            </div>
            <div className={classes.rowContainer}>
              <FormikAutocomplete
                name="zonaAfectada"
                label={`Zona Afectada ${
                  fields.zonaAfectada.required ? "*" : ""
                }`}
                variant="outlined"
                options={zonasAfectadas.data}
                getOptions={() =>
                  dispatch(getFormZonasAfectadas(accident.mlCloudCoreArtId))
                }
                className={classes.input}
                disabled={disabled}
              />
            </div>
            <div className={classes.rowContainer}>
              <FormikSelect
                name="gravedad"
                value={iamiModel.gravedad}
                label={`Gravedad ${fields.gravedad.required ? "*" : ""}`}
                variant="outlined"
                className={classes.input}
                disabled={disabled}>
                {fields.gravedad.options.map((g) => (
                  <MenuItem value={g} key={g}>
                    {g}
                  </MenuItem>
                ))}
              </FormikSelect>
            </div>
            <FormSectionDivider title="Profesional" />
            <ProfessionalControl
              disabled={disabled}
              fields={fields}
              setFieldValue={setFieldValue}
              profesionalPreCarga={iamiModel.profesionalApellidoNombre}
              error={professionalError}
              setError={setProfessionalError}
              especialidadesProfesional={fields.especialidadProfesional}
              values={values}
              habilitarEspecialidadInforme={true}
              isView={isView}
            />

            {model.configuration.showAttachments && (
              <div>
                <FormSectionDivider title="Adjuntos" />
                <div className={classes.rowContainer}>
                  <DropZone
                    files={files}
                    setFiles={setFiles}
                    configuration={model.configuration}
                    error={attachmentError}
                    setError={setAttachmentError}
                    disabled={disabled}
                    selectedDocument={selectedDocument}
                    accept={model.configuration.availableExtensions}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={classes.actionsContainer}>
            <FormikActions handleCancel={handleClose} disabled={disabled} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Iami;
