import { IconButton, Tooltip } from "@material-ui/core";
import { Add, Edit } from "@material-ui/icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFormProfessionals } from "../../../../actions/forms";
import FormikAutocomplete from "../../../commons/formik/formikAutocomplete";
import { useStyles } from "../formStyle";

const Professional = ({
  disabled,
  fields,
  setFieldValue,
  setValuesInProfessionalModel,
  setearEspecialidadesInforme,
  professionalModel,
  handleOpenProfessional,
  handleOpenEdit,
  setEdit,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const accident = useSelector((state) => state.accidentDashboard.accident);
  const professionals = useSelector((state) => state.forms.professionals);

  return (
    <div className={classes.rowContainer}>
      <FormikAutocomplete
        name="profesionalApellidoNombre"
        label={`Profesional Apellido y Nombre ${
          fields.profesionalApellidoNombre.required ? "*" : ""
        }`}
        variant="outlined"
        options={professionals.data}
        getOptions={() =>
          dispatch(getFormProfessionals(accident.mlCloudCoreArtId))
        }
        callBack={(value) => {
          setFieldValue("informacionSensible", false);
          if (!value) {
            setValuesInProfessionalModel();
            setearEspecialidadesInforme();
            setFieldValue("especialidadProfesional", "");
            return;
          }

          setValuesInProfessionalModel(value);
          if (!value.matricula || !value.especialidad) {
            setEdit(true);
            handleOpenProfessional();
            return;
          }
          setFieldValue("profesionalMnMp", value.matricula);
          setFieldValue("especialidadProfesional", value.especialidad);
          setearEspecialidadesInforme(value.nombre);
        }}
        className={classes.input}
        returnProp="nombre"
        renderOption={(option) =>
          `${option.nombre} ${option.matricula ? "-" : ""} ${
            option.matricula || ""
          } ${option.nombreEspecialidad ? "-" : ""}  ${
            option.nombreEspecialidad || ""
          }`
        }
        filterOptions={(option) => option.matricula + option.nombre}
        disabled={disabled}
      />
      {!professionalModel.profesionalApellidoNombre ? (
        <Tooltip
          title="Agregar Profesional"
          onClick={disabled ? undefined : handleOpenProfessional}
          className={
            disabled ? classes.addAppointmentDisabled : classes.addAppointment
          }>
          <IconButton disabled={disabled}>
            <Add style={{ width: 40, height: 40 }} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip
          title="Editar Profesional"
          onClick={disabled ? undefined : handleOpenEdit}
          className={
            disabled ? classes.addAppointmentDisabled : classes.addAppointment
          }>
          <IconButton disabled={disabled}>
            <Edit style={{ width: 40, height: 40 }} />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default Professional;
